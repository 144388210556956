import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import mainPage from "./home";
import aboutPage from "./about";
import contactPage from "./contact-us";
import shopPage from "./shop";
import cartPage from "./cart";
import checkoutPage from "./checkout";
import blogPostPage from "./blog";
import dealsPage from "./deals";
import productByCategory from "./product-by-category";

class Main extends Component {
  render() {
    return (		
		<Switch>				
			<Route path="/" exact component={mainPage} />
			<Route path="/shop" exact component={shopPage} />
			<Route path="/cart" exact component={cartPage} />
			<Route path="/checkout" exact component={checkoutPage} />
			<Route path="/about" exact component={aboutPage} />			
			<Route path="/contact-us" exact component={contactPage} />
			<Route path="/blog/:alias_url" exact component={blogPostPage} />
			<Route path="/deals" exact component={dealsPage} />
			<Route path="/product-by-category/:id" exact component={productByCategory} />
			<Redirect to="/app" />
		</Switch>       
	)
	
	
  }
}
export default Main;

/*import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Main extends Component {
  render() {
    return <Redirect to="/app" />
  }
}
export default Main;*/

