import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
const MobileNavMenu = () => {
	const local_user_data = localStorage.getItem('user_data');
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <NavLink to={"/"}>Home</NavLink>          
        </li>
        <li>
          <NavLink to={"/about"}>About</NavLink>          
        </li>
        <li>
          <NavLink to={"/shop"}>Shop</NavLink>          
        </li>
		<li>
          <NavLink to={"/deals"}>Deals</NavLink>          
        </li>
        <li>
          <NavLink to={"/contact-us"}>Contact Us</NavLink>          
        </li>
		{local_user_data ? <li>
          <NavLink to={"/app/dashboard"}>My Account</NavLink>          
        </li>:<li>
          <NavLink to={"/user/login"}>Login</NavLink>          
        </li>}
      </ul>
    </nav>
  );
};

const MobileMenu = () => {
  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
    const anchorNavLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", e => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorNavLinks.length; i++) {
      anchorNavLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
      <button
        className="offcanvas-menu-close"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="fas fa-times"></i>
      </button>
      <div className="offcanvas-wrapper ">
        <div className="offcanvas-inner-content text-right">
          {/* mobile nav menu */}
          <MobileNavMenu />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
