import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu, Button
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';

import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  loginAsSubscriber,
  changeLocale
} from "../../redux/actions";

import {
  menuHiddenBreakpoint,
  searchPath, BASE_URL
} from "../../constants/defaultValues";

import { MobileMenuIcon, MenuIcon } from "../../components/svg";
import TopnavEasyAccess from "./Topnav.EasyAccess";
import TopnavNotifications from "./Topnav.Notifications";
import ModalHelpContainer from "./ModalHelpContainer";

import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, dataEncryptKey} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class TopNav extends Component {
  constructor(props) {
    super(props);

	this.LoginBackToAdmin = this.LoginBackToAdmin.bind(this);
    this.state = {
      isInFullScreen: false,
      searchKeyword: "",
	  modalHelp: false,
	  facility_data:null
    };
  }
  
	componentDidMount() {
		var form_data = new FormData();
		form_data.append('user_id',localStorage.getItem('user_id'));
		if(localStorage.getItem('user_id')!=null){
			axios.post(REST_API_END_POINT+'register/get-user-data',form_data).then((res)=>{ 
				if(res.data.id){
					var user_session=JSON.parse(localStorage.getItem('user_data'));
					user_session.first_name=res.data.first_name;
					user_session.last_name=res.data.last_name;
					user_session.profile_pic=res.data.profile_pic;
					localStorage.setItem('user_data', JSON.stringify(user_session));
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
		
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id!=null){
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{
				this.setState({ facility_data: res.data.configurations, blocking:false });
			}).catch(e =>{ 
				console.log("Addition failed, Error ", e) 
				this.setState({ blocking:false });
			});
		}
		
	}
	
	toggleModalHelp = () => {
		this.setState({
		  modalHelp: !this.state.modalHelp
		});
	};

	handleChangeLocale = locale => {
		this.props.changeLocale(locale);
	};
	
	isInFullScreen = () => {
		return (
		  (document.fullscreenElement && document.fullscreenElement !== null) ||
		  (document.webkitFullscreenElement &&
			document.webkitFullscreenElement !== null) ||
		  (document.mozFullScreenElement &&
			document.mozFullScreenElement !== null) ||
		  (document.msFullscreenElement && document.msFullscreenElement !== null)
		);
	};
	
	handleSearchIconClick = e => {
		if (window.innerWidth < menuHiddenBreakpoint) {
		  let elem = e.target;
		  if (!e.target.classList.contains("search")) {
			if (e.target.parentElement.classList.contains("search")) {
			  elem = e.target.parentElement;
			} else if (
			  e.target.parentElement.parentElement.classList.contains("search")
			) {
			  elem = e.target.parentElement.parentElement;
			}
		  }

		  if (elem.classList.contains("mobile-view")) {
			this.search();
			elem.classList.remove("mobile-view");
			this.removeEventsSearch();
		  } else {
			elem.classList.add("mobile-view");
			this.addEventsSearch();
		  }
		} else {
		  this.search();
		}
	};
	
	addEventsSearch = () => {
		document.addEventListener("click", this.handleDocumentClickSearch, true);
	};
	
	removeEventsSearch = () => {
		document.removeEventListener("click", this.handleDocumentClickSearch, true);
	};

	handleDocumentClickSearch = e => {
		let isSearchClick = false;
		if (
		  e.target &&
		  e.target.classList &&
		  (e.target.classList.contains("navbar") ||
			e.target.classList.contains("simple-icon-magnifier"))
		) {
		  isSearchClick = true;
		  if (e.target.classList.contains("simple-icon-magnifier")) {
			this.search();
		  }
		} else if (
		  e.target.parentElement &&
		  e.target.parentElement.classList &&
		  e.target.parentElement.classList.contains("search")
		) {
		  isSearchClick = true;
		}

		if (!isSearchClick) {
		  const input = document.querySelector(".mobile-view");
		  if (input && input.classList) input.classList.remove("mobile-view");
		  this.removeEventsSearch();
		  this.setState({
			searchKeyword: ""
		  });
		}
	};
	
	handleSearchInputChange = e => {
		this.setState({
		  searchKeyword: e.target.value
		});
	};
	
	handleSearchInputKeyPress = e => {
		if (e.key === "Enter") {
		  this.search();
		}
	};

	search = () => {
		this.props.history.push(searchPath + "/" + this.state.searchKeyword);
		this.setState({
		  searchKeyword: ""
		});
	};

	toggleFullScreen = () => {
		const isInFullScreen = this.isInFullScreen();

		var docElm = document.documentElement;
		if (!isInFullScreen) {
		  if (docElm.requestFullscreen) {
			docElm.requestFullscreen();
		  } else if (docElm.mozRequestFullScreen) {
			docElm.mozRequestFullScreen();
		  } else if (docElm.webkitRequestFullScreen) {
			docElm.webkitRequestFullScreen();
		  } else if (docElm.msRequestFullscreen) {
			docElm.msRequestFullscreen();
		  }
		} else {
		  if (document.exitFullscreen) {
			document.exitFullscreen();
		  } else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		  } else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		  } else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		  }
		}
		this.setState({
		  isInFullScreen: !isInFullScreen
		});
	};

	handleLogout = () => {
		this.props.logoutUser(this.props.history);
	};

	menuButtonClick = (e, menuClickCount, containerClassnames) => {
		e.preventDefault();

		setTimeout(() => {
		  var event = document.createEvent("HTMLEvents");
		  event.initEvent("resize", false, false);
		  window.dispatchEvent(event);
		}, 350);
		this.props.setContainerClassnames(
		  ++menuClickCount,
		  containerClassnames,
		  this.props.selectedMenuHasSubItems
		);
	};
	
	mobileMenuButtonClick = (e, containerClassnames) => {
		e.preventDefault();
		this.props.clickOnMobileMenu(containerClassnames);
	};
	
	LoginBackToAdmin() {
		const user_id = localStorage.getItem('user_id');
		this.props.loginAsSubscriber({user_id:1,from_id:user_id}, this.props.history);
	}

  render() {
    const { containerClassnames, menuClickCount } = this.props;
    //const { messages } = this.props.intl;
	const user_id = localStorage.getItem('user_id');
	const user_data = JSON.parse(localStorage.getItem('user_data'));
	const login_admin = localStorage.getItem('login_admin');
	var is_admin=false;	
	if(login_admin!=null && typeof login_admin!='undefined' && user_id!=1){
		is_admin=true;
	}
	
	let facility_data = this.state.facility_data;
	
	let default_logo = <a className="navbar-logo" href="/">
			  <span className="logo d-none d-xs-block" />
			  <span className="logo-mobile d-block d-xs-none" />
			</a>;
			
	if(facility_data!=null){
		if(facility_data.facility_logo!=null && facility_data.facility_mobile_logo!=null){
			default_logo=<a className="navbar-logo navbar-logo-img" href="/">
			  <img src={BASE_URL+'pages/'+facility_data.facility_logo} className="img-responsive d-none d-sm-block"  />
			  <img src={BASE_URL+'pages/'+facility_data.facility_mobile_logo} className="logo-mobile-img img-responsive d-block d-sm-none"  />
			</a>
		}else if(facility_data.facility_logo!=null){
			default_logo=<a className="navbar-logo navbar-logo-img" href="/">
				<img src={BASE_URL+'pages/'+facility_data.facility_logo} className="img-responsive d-none d-sm-block"  />
				<span className="logo-mobile d-block d-sm-none" />
			</a>
		}else if(facility_data.facility_mobile_logo!=null){
			default_logo=<a className="navbar-logo navbar-logo-img" href="/">
				<span className="logo d-none d-sm-block" />
				<img src={BASE_URL+'pages/'+facility_data.facility_mobile_logo} className="logo-mobile-img img-responsive d-block d-sm-none"  />
			</a>
		}
	}
	
    return (<div>
		{is_admin ? <div className="logged-in-as-user-bar fixed-top">Logged in as <strong>{user_data.first_name}{" "}{user_data.last_name}</strong> <Button color="info" size="xs" onClick={this.LoginBackToAdmin}>Switch back to admin</Button></div>:""}	
		<nav className={"navbar fixed-top "+(is_admin ? " logged-in-as-user-bar-exist":"")}>
			
			<NavLink
			  to="#"
			  className="menu-button d-none d-md-block"
			  onClick={e =>
				this.menuButtonClick(e, menuClickCount, containerClassnames)
			  }
			>
			  <MenuIcon />
			</NavLink>
			<NavLink
			  to="#"
			  className="menu-button-mobile d-xs-block d-sm-block d-md-none"
			  onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
			>
			  <MobileMenuIcon />
			</NavLink>

			{default_logo}
			<div className="mt-2" style={{marginLeft: 'auto'}}>
				<h3 className="d-inline-block mb-0" style={{color:'#967507'}}>{facility_data!=null ? facility_data.facility_name:""}</h3>
			</div>
			<div className="ml-auto">				
			  <div className="header-icons d-inline-block align-middle">				
				{user_data.user_type!="2" ? <TopnavEasyAccess />:""}
				{/*<TopnavNotifications />*/}
				
				<ModalHelpContainer
					modalOpen={this.state.modalHelp}
					toggleModal={this.toggleModalHelp}
				  />
				<button
				  className="header-icon btn btn-empty d-inline-block"
				  type="button"
				  id="fullScreenButton"
				  onClick={this.toggleFullScreen}
				>
				  {this.state.isInFullScreen ? (
					<i className="simple-icon-size-actual d-block" />
				  ) : (
					<i className="simple-icon-size-fullscreen d-block" />
				  )}
				</button>
			  </div>
			  <div className="user d-inline-block">
				<UncontrolledDropdown className="dropdown-menu-right">
				  <DropdownToggle className="p-0" color="empty">
					<span className="name mr-1" id="user_profile_name">{user_data!=null ? user_data.first_name+' '+user_data.last_name:""}</span>
					<span>
						<img id="user_profile_img" alt="Profile" src={user_data!=null ? BASE_URL+'users/'+user_data.profile_pic:"/assets/img/profile-pic-l-2.jpg"} />
					</span>
				  </DropdownToggle>
				  <DropdownMenu className="mt-3" right>
					<DropdownItem className="p-0">
						<NavLink to="/app/profile" className="d-block px-4 py-2">
							Profile
						</NavLink>	
					</DropdownItem>                
					<DropdownItem divider />
					<DropdownItem onClick={() => this.handleLogout()}>
					  Sign out
					</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
			  </div>
			</div>
		</nav>
    </div>);
  }
}

const mapStateToProps = ({ menu, settings }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale } = settings;
  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale
  };
};
export default injectIntl(
  connect(
    mapStateToProps,
    { setContainerClassnames, clickOnMobileMenu, logoutUser, loginAsSubscriber, changeLocale }
  )(TopNav)
);
