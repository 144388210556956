import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DealModal from "./DealModal";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../common/CustomBootstrap";


const DealGridListSingle = ({
  product,
  currency,
  addToCart,
  cartItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();

  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  product.product_stock = parseInt(product.product_stock)
	
  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img">            
            <img
                className="default-img"
                src={product.thumbnail_url}
                alt=""
            />
            <div className="product-action">
				{product.product_stock && product.product_stock > 0 ? "": (<div className="pro-same-action pro-cart">
                  <button disabled className="active">
                    Out of Stock
                  </button>
                </div>)}              
              
            </div>
          </div>
          <div className="product-content">
		         <Row>
				     <Colxx lg={7}>
						 <h3 dangerouslySetInnerHTML={{ __html: product.name }} ></h3>
						 <div className="product-price">
							 <span>{currency.currencySymbol + parseFloat(finalProductPrice).toFixed(2)} </span>
						 </div>
					 </Colxx>
					 <Colxx lg={5}>
					  <div className="pro-same-action pro-quickview text-right">
						<button style={{background:"#000",color:"#fff",border:" .09375rem solid #000",borderRadius:"10px"}} className="btn-home pl-2 pr-2 pt-2 pb-2" onClick={() => setModalShow(true)} title="Quick View">
						  <i className="" /> Buy Now
						</button>
					  </div>
					 </Colxx>
				 </Row>
          </div>
        </div>
      </div>
      {/* product modal */}
      <DealModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        finalproductprice={finalProductPrice}
        cartitem={cartItem}
        addtocart={addToCart}
        addtoast={addToast}
      />
    </Fragment>
  );
};

DealGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  cartItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default DealGridListSingle;
