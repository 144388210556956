import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import AppLocale from "./lang";
import ColorSwitcher from "./components/common/ColorSwitcher";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import { isMultiColorActive } from "./constants/defaultValues";
import main from "./views";
import app from "./views/app";
import administrator from "./views/administrator";
import user from "./views/user";
import error from "./views/error";

import CacheBuster from "./CacheBuster";

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
	const local_user_id = localStorage.getItem('user_id');
	if(local_user_id==1){
		window.location = "/administrator/dashboard"
	}
	return <Route
    {...rest}
    render={props =>{
			if(localStorage.getItem('redirect_url')){				
				var redirect_url = localStorage.getItem('redirect_url');
				localStorage.removeItem('redirect_url');
				window.location = redirect_url;
				return;
			}
		 return authUser ? (
			<Component {...props} />
		  ) : (
			<Redirect
			  to={{
				pathname: "/user/login",
				state: { from: props.location }
			  }}
			/>
		  )
		}
    }
  />
};

const AdminRoute = ({ component: Component, authUser, ...rest }) => (
	
	<Route
    {...rest}
    render={props =>{
			if(localStorage.getItem('redirect_url')){				
				var redirect_url = localStorage.getItem('redirect_url');
				localStorage.removeItem('redirect_url');
				window.location = redirect_url;
				return;
			}
		  return authUser ? <Component {...props} /> : (
			<Redirect
			  to={{
				pathname: "/user/login",
				state: { from: props.location }
			  }}
			/>
		  )
		}
    }
  />
);

class App extends Component {
  render() {
    const { locale, loginUser } = this.props;
    const currentAppLocale = AppLocale[locale];
	var force_password_reset=false;
	var pathname = window.location.pathname.split('/');
	
	const local_user_data = localStorage.getItem('user_data');
	var is_admin = false;
	if(local_user_data){
		var user_data = JSON.parse(local_user_data);
		force_password_reset=user_data.force_password_reset;
		if(user_data.user_type=="2"){
			is_admin=true;			
		}
	}
	//console.log(pathname);
	
    return (
		<CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
		  return (<div className="h-100">
			<IntlProvider
			  locale={currentAppLocale.locale}
			  messages={currentAppLocale.messages}
			>
			  <React.Fragment>
				<NotificationContainer />
				{isMultiColorActive && <ColorSwitcher />}
				<Router>
				  <Switch>
					<Route path="/error" exact component={error} />
					<Route path="/" exact component={main} />
					<Route path="/shop" exact component={main} />
					<Route path="/cart" exact component={main} />
					<Route path="/checkout" exact component={main} />
					<Route path="/about" exact component={main} />
					<Route path="/contact-us" exact component={main} />
					<Route path="/blog/:alias_url" exact component={main} />
					<Route path="/product-by-category/:id" exact component={main} />
					<Route path="/deals" exact component={main} />
					
					{is_admin ? <AdminRoute path="/administrator" authUser={loginUser}  component={administrator} />:""}
					<AuthRoute path="/app" authUser={loginUser} force_password_reset={force_password_reset} component={app} />
					{loginUser!=null ? (
							(is_admin ? <Redirect
								  to={{
									pathname: "/administrator",
									state: { from: this.props.location }
								  }}
							/>:<Redirect
								  to={{
									pathname: "/app",
									state: { from: this.props.location }
								  }}
							/>)
						) : (<Route path="/user" component={user} />)}
					
					<Redirect to="/error" />
				  </Switch>
				</Router>
			  </React.Fragment>
			</IntlProvider>
		  </div>);
        }}
      </CacheBuster>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user: loginUser } = authUser;
  const { locale } = settings;
  return { loginUser, locale };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
