import React, { Component, Fragment } from "react";
import { Row, Card, Button, Select, CardBody, FormGroup, Input, CustomInput, Badge, Label } from "reactstrap";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";

import swal from 'sweetalert';
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import ReactTable from "react-table";

import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import DeliveryModal from "../../../containers/pointofsale/DeliveryModal";
import CashPaymentModal from "../../../containers/pointofsale/CashPaymentModal";
import ChangePaymentModal from "../../../containers/pointofsale/ChangePaymentModal";
import DebitPaymentModal from "../../../containers/pointofsale/DebitPaymentModal";
import RedeemPointModal from "../../../containers/pointofsale/RedeemPointModal";
import DiscountModal from "../../../containers/pointofsale/DiscountModal";
import CouponModal from "../../../containers/pointofsale/CouponModal";

import PrintLabelModal from "../../../containers/pointofsale/PrintLabelModal";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, CUSTOMER_ORDER_STATUS} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import moment from "moment";

import { getCookie, setCookie } from "../../../helpers/Utils";
import Orders from "../../../components/pos/Orders";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class placeOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {			
		    activeFirstTab: "1",
			modalOpenDelivery: false,
			modalOpenCashPayment: false,
			modalOpenChangePayment: false,
			modalOpenDebit: false,
			modalOpenPricePoints: false,
			modalOpenDiscount: false,
			modalOpenCoupon: false,
			modalOpenPrintLabel: false,
			data:[],
			search: '',
			points: [],
			selectedIds:[],
			price_point_id:0,
			selectAll: 0,
			
			blocking:true,
			currency_code:'USD',
			currency_rate:1,
			currency_symbol:'$',
			
			customer_id:0,
			product_id:0,
			customer:null,
			bogo_infos: [],
			coupon_infos: [],
			applied_coupon_infos: [],
			auto_apply_coupons: [],
			orders: [],
			products: [],
			categories: [],
			price_points: [],
			thc_categories: {
				medical_marijuana:[],
				edibles:[],
				non_edibles:[],
			},
			currencies: [],
			cart: [],
			cart_totals:{
				subtotal:0,
				discount:0,
				tax:0,
				total:0,				
				thc_medical:0,
				thc_edible:0,
				thc_non_edible:0,
				auto_apply_discount:0,
				total_due:0,				
			},
			bogo_pop_up: 0,
			parent_product: 0,
			bogo_products: [],
			coupon:'',
			facility:null,
			print_pos_label:null,
			payment_data:null,
			delivery_date:null,
			checkout_mode:"",
			hypur_transaction_id:"",
			change_data:null,
			drawer_id:0,
			order_id:0,
			success_msg:"",
			drawer_data:null
		};
		
		this.recalculateTotals = this.recalculateTotals.bind(this); 
		this.placeOrderFinal = this.placeOrderFinal.bind(this); 
		this.resetPaymentData = this.resetPaymentData.bind(this); 
		this.updatePaymentData = this.updatePaymentData.bind(this); 
		this.updateChangeData = this.updateChangeData.bind(this); 
	}
	
	componentDidMount(){  
		const user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);	
        formData.append('order_id',this.props.match.params.order_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/process-payment',formData).then((res)=>{
			var data = res.data;
		
			if(data.customer){
				var cart = data.cart;
				var drawer_data = data.drawer;
				var whole_order_data = data.whole_order_data;
				var customer_id = data.customer_id;
				var currency_code = getCookie('currency_code','string');
				if(currency_code==null){
					currency_code='USD';
				}
				
				if(cart==null){
					cart=[];
				}
				
				var payment_data = null; //getCookie('payment_data','object');				
				var change_data = getCookie('change_data','object');				
				if(payment_data==null){
					payment_data={
						'1_s' : 0,
						'2_s' : 0,
						'5_s' : 0,
						'10_s' : 0,
						'20_s' : 0,
						'50_s' : 0,
						'100_s' : 0,
						'cents' : 0,
						'nickles' : 0,
						'dimes' : 0,
						'quarters' : 0,
						'50_cents' : 0,
						'1_d' : 0,
						'cash' : 0,
						'card_name' : "",
						'card_number' : "",
						'debit' : 0,
						'discount' : 0,
						'discount_value' : 0,
						's_discount_type' : 1,
						'discount_reason' : "",
						'discount_product' : "",
						'coupon_code' : "",
						'coupon_discount' : 0,
						'points' : 0,
						'point_discount' : 0,
					};
				}
				
				if(whole_order_data.points_used!="" && parseInt(whole_order_data.points_used)!=NaN){
					payment_data.points=parseInt(whole_order_data.points_used);
				}
				
				if(whole_order_data.point_discount!="" && parseFloat(whole_order_data.point_discount)!=NaN){
					payment_data.point_discount=parseFloat(whole_order_data.point_discount);
				}
				
				if(whole_order_data.coupon_discount!="" && parseFloat(whole_order_data.coupon_discount)!=NaN && parseFloat(whole_order_data.coupon_discount)>0){
					payment_data.coupon_discount=parseFloat(whole_order_data.coupon_discount);
					let coupon_code = whole_order_data.coupon_code.split(",");
					let last_coupon_code = coupon_code.pop();
					while(last_coupon_code=="" && coupon_code.length>0){
						last_coupon_code = coupon_code.pop()
					}
					if(last_coupon_code!=''){
						payment_data.coupon_code = last_coupon_code;
					}
					
				}
				
				if(whole_order_data.manual_discount!="" && parseFloat(whole_order_data.manual_discount)!=NaN && parseFloat(whole_order_data.manual_discount)>0){
					payment_data.discount=parseFloat(whole_order_data.manual_discount);
					payment_data.s_discount_type=parseInt(whole_order_data.manual_discount_type);
					payment_data.discount_value=parseFloat(whole_order_data.manual_discount_value);
					payment_data.discount_reason=whole_order_data.reason_for_discount;
				}
				
				setCookie('p_payment_data',payment_data);
				setCookie('change_data',change_data);
				
				const currencies= data.currencies;
				const selected_currency = currencies.find(row=>{ return row.currency_code==currency_code });
				var currency_rate = 1;
				var currency_symbol = '$';
				if(selected_currency!=null){
					currency_rate = selected_currency.currency_rate;
					currency_symbol = selected_currency.currency_symbol;
				}
				
				
				
				this.setState({
					customer_id,
					customer: data.customer,
					bogo_infos: data.bogo_infos,
					coupon_infos: data.coupon_infos,
					auto_apply_coupons: data.auto_apply_coupons,
					products: data.products,
					currencies,
					categories: data.categories,
					price_points: data.price_points,
					thc_categories: data.thc_categories,
					facility: data.facility,
					drawer_id: data.drawer_id,
					currency_code,
					currency_rate,
					currency_symbol,
					blocking:false,
					payment_data,
					change_data,
					drawer_data
				},()=>{
					if(cart!=null){
						this.recalculateTotals(cart);
					}
				});
			}else{
				swal("Warning!",data.msg,"warning").then(()=>{
					window.location="/app/point-of-sale/all-orders";
				});
				setTimeout(()=>{
					window.location="/app/point-of-sale/all-orders";
				},4000)
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			setTimeout(()=>{
				window.location="/app/point-of-sale/all-orders";
			},1000)
		});
	}
		
	toggleModalDelivery = () => {
		this.setState({
			modalOpenDelivery: !this.state.modalOpenDelivery
		});
	};
	
	toggleModalCashPayment = () => {
		this.setState({
			modalOpenCashPayment: !this.state.modalOpenCashPayment
		});
	};
	
	toggleModalChangePayment = () => {
		this.setState({
			modalOpenChangePayment: !this.state.modalOpenChangePayment
		});
	};
	
	toggleModalDebit = () => {
		this.setState({
			modalOpenDebit: !this.state.modalOpenDebit
		});
	};
	
	toggleModalPricePoints = () => {
		this.setState({
			modalOpenPricePoints: !this.state.modalOpenPricePoints
		});
	};
	
	toggleModalDiscount = () => {
		this.setState({
			modalOpenDiscount: !this.state.modalOpenDiscount
		});
	};
	
	toggleModalCoupon = () => {
		this.setState({
			modalOpenCoupon: !this.state.modalOpenCoupon
		});
	};
	
	async recalculateTotals(cart=null){
		if(cart==null){
			cart=this.state.cart;
		}
		
		var coupons = [];
		if(this.state.payment_data.coupon_code!=''){
			coupons = this.state.payment_data.coupon_code.split(',');		
		}
		
		const customer_data=this.state.customer;
		const payment_data=this.state.payment_data;
		const auto_apply_coupon_datas=this.state.auto_apply_coupons;
		const coupon_infos=this.state.coupon_infos;
		const applied_coupon_infos=this.state.applied_coupon_infos;
		const thc_categories=this.state.thc_categories;
			
		var coupon_discount=0;
		var auto_apply_discount=0;
		var coupon_datas=[];
		for(let coupon of coupons){
			if(coupon!=''){
				let coupon_data = applied_coupon_infos.find(r=>{ return r.code==coupon }); 
				if(coupon_data==null){
					const user_id = localStorage.getItem('user_id');
					const customer_id = this.state.customer_id;
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('customer_id',customer_id);
					formData.append('coupon',coupon);
					await axios.post(REST_API_END_POINT+'point-of-sale/get-applied-coupon-detail',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							coupon_data = data.coupon_data;
							applied_coupon_infos.push(coupon_data);
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
				if(coupon_data!=null){
					if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
						coupon_discount += parseFloat(coupon_data['amount']);
					}
					coupon_datas.push(coupon_data);
				}
			}			
		}
		
		for(let coupon_data of auto_apply_coupon_datas){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){
				auto_apply_discount+=parseFloat(coupon_data['amount']);
			}		
		}
		
		var cart_totals = {
			subtotal:0,
			discount:0,
			tax:0,
			total:0,
			thc_medical:0,
			thc_edible:0,
			thc_non_edible:0,
			auto_apply_discount:0,
			total_due:0,
		}
		
		for(let key in cart){
			let row = cart[key];		
			row.thc = row.actual_weight;
			if(auto_apply_coupon_datas.length && row.deal_id==0){		
				for(let coupon_data of auto_apply_coupon_datas){
					let product_ids=coupon_data['product_id'].split(',');
					let	category_ids=coupon_data['category_id'].split(',');
					let product_discount = 0;
					if(((product_ids.indexOf(row.id.toString())!=-1 && coupon_data['is_product']=='3') || (category_ids.indexOf(row.category_id.toString())!=-1 && coupon_data['is_product']=='4') || (category_ids.indexOf(row.sub_category.toString())!=-1 && coupon_data['is_product']=='4') || coupon_data['is_product']=='2') && row['options']['is_free']==0){
						if(coupon_data['type']=='0'){
							product_discount=parseFloat(coupon_data['amount']);	
						}else{ 
							product_discount=((row.unit_price+row.unit_sales_tax)*row.qty)*parseFloat(coupon_data['amount'])/100;
						}
						if(product_discount>parseFloat(((row.unit_price+row.unit_sales_tax)*row.qty).toFixed(2))){							
							product_discount=0;
						}
					}
					row.discount = parseFloat(product_discount.toFixed(2));
				}
			}else{
				row.discount =0;
			}
			
			if(row.coupon_code!=''){
				let coupon_data = applied_coupon_infos.find(r=>{ return r.code==row.coupon_code }); 
				if(coupon_data==null){
					const user_id = localStorage.getItem('user_id');
					const customer_id = this.state.customer_id;
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('customer_id',customer_id);
					formData.append('coupon',row.coupon_code);
					await axios.post(REST_API_END_POINT+'point-of-sale/get-applied-coupon-detail',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							coupon_data = data.coupon_data;
							applied_coupon_infos.push(coupon_data);
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
				if(coupon_data!=null){
					let product_ids=coupon_data['product_id'].split(',');
					let	category_ids=coupon_data['category_id'].split(',');
					let product_discount = 0;
			
					if(((product_ids.indexOf(row.id.toString())!=-1 && coupon_data['is_product']=='3') || (category_ids.indexOf(row.category_id.toString())!=-1 && coupon_data['is_product']=='4') || (category_ids.indexOf(row.sub_category.toString())!=-1 && coupon_data['is_product']=='4') || coupon_data['is_product']=='2') && row['options']['is_free']==0){
						if(coupon_data['type']=='0'){
							product_discount=parseFloat(coupon_data['amount']);	
						}else{ 
							product_discount=((row.unit_price+row.unit_sales_tax)*row.qty)*parseFloat(coupon_data['amount'])/100;
						}
						if(product_discount>parseFloat(((row.unit_price+row.unit_sales_tax)*row.qty).toFixed(2))){
							product_discount=0;
						}
					}					
					row.coupon_discount = parseFloat(product_discount.toFixed(2));
				}
			}else{
				row.coupon_discount =0;
			}
			
			let unit_exise_tax = JSON.parse(JSON.stringify(row.unit_exise_tax));
			let unit_sales_tax = JSON.parse(JSON.stringify(row.unit_sales_tax));
			
			if(row.unit_price!=0){					
				let tot_price=row.unit_price*row.qty-row.discount-row.coupon_discount;
				//unit_exise_tax=(unit_exise_tax/row.unit_price)*tot_price/row.qty;
				//unit_sales_tax=(unit_sales_tax/row.unit_price)*tot_price/row.qty;
				
				row.price = tot_price/row.qty+unit_exise_tax+unit_sales_tax;
			}
			
			row.exise_tax = parseFloat((row.qty*unit_exise_tax).toFixed(2));
			row.sales_tax = parseFloat((row.qty*unit_sales_tax).toFixed(2));
			row.tax = row.exise_tax+row.sales_tax;
			cart_totals.tax+=row.tax;
			
			cart_totals.subtotal += parseFloat((row['unit_price']*row['qty']).toFixed(2));
			cart_totals.discount += parseFloat((row['discount']+row['coupon_discount']).toFixed(2));
			cart_totals.total += parseFloat((row['unit_price']*row['qty']-row['discount']-row['coupon_discount']+row.tax).toFixed(2));
			if(typeof row.thc=='undefined'){
				row.thc=0;
			}
			if(thc_categories.medical_marijuana.indexOf(row['category_id'])!=-1 || thc_categories.medical_marijuana.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_medical+=parseFloat(row.thc);
			}else if(thc_categories.edibles.indexOf(row['category_id'])!=-1 || thc_categories.edibles.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_edible+=parseFloat(row.thc);
			}else if(thc_categories.non_edibles.indexOf(row['category_id'])!=-1 || thc_categories.non_edibles.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_non_edible+=parseFloat(row.thc);
			}
			
			cart[key] = row;
		}
		
		if(coupon_datas.length){
			for(let coupon_data of coupon_datas){
				if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
					coupon_discount+=cart_totals.total*parseFloat(coupon_data['amount'])/100;
				}
			}
		}
		
		if(cart_totals.total<coupon_discount){
			coupon_discount=0;
		}		
		
		for(let coupon_data of auto_apply_coupon_datas){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
				auto_apply_discount+=cart_totals.total*parseFloat(coupon_data['amount'])/100;
			}		
		}
		
		if(cart_totals.total<auto_apply_discount){
			auto_apply_discount=0;
		}
		
		cart_totals.discount += parseFloat(coupon_discount+auto_apply_discount+((payment_data.discount_value+payment_data.point_discount)/this.state.currency_rate));
		cart_totals.total -= parseFloat(coupon_discount+auto_apply_discount+((payment_data.discount_value+payment_data.point_discount)/this.state.currency_rate));
		cart_totals.auto_apply_discount = auto_apply_discount;
		
		cart_totals.total_due = cart_totals.total*this.state.currency_rate - payment_data.cash - payment_data.debit;
		
		this.setState({ cart, cart_totals });
		setCookie('cart_process',cart);
	}
	
	submitDelivery = (delivery_date) =>{
		this.setState({ delivery_date, checkout_mode:"Delivery" },()=>{
			this.placeOrderFinal();
		})
	}	
	
	updatePaymentData = (payment_data) =>{
		var cart_totals = this.state.cart_totals;
		cart_totals.total_due = cart_totals.total*this.state.currency_rate - payment_data.cash - payment_data.debit;
		this.setState({ payment_data, cart_totals });
		setCookie('p_payment_data',payment_data);
	}
	
	updateChangeData = (change_data) =>{
		this.setState({ change_data },()=>{ this.placeOrderFinal(); });
		setCookie('change_data',change_data);
	}
	
	placeOrderFinal = () =>{
		const currency_rate = this.state.currency_rate;
		const currency_code = this.state.currency_code;
		
		const delivery_date = this.state.delivery_date;
		const checkout_mode = this.state.checkout_mode;
		const hypur_transaction_id = this.state.hypur_transaction_id;
		const payment_data = this.state.payment_data;
		const change_data = this.state.change_data;
		const cart_totals = this.state.cart_totals;
		var cart = JSON.parse(JSON.stringify(this.state.cart));
		
		cart = cart.map(row=>{
			row.unit_price = row.unit_price*currency_rate;
			row.price = row.price*currency_rate;
			row.discount = row.discount*currency_rate;
			row.exise_tax = row.exise_tax*currency_rate;
			row.sales_tax = row.sales_tax*currency_rate;
			row.tax = row.tax*currency_rate;
			row.free_discount = row.free_discount*currency_rate;
			row.coupon_discount = row.coupon_discount*currency_rate;
			return row;
		})
		
		const user_id = localStorage.getItem('user_id');
		const customer_id = this.state.customer_id;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var bogo_code = getCookie('bogo_code','string');
		
        var formData = new FormData(document.getElementById("checkoutForm")); 
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);		
        formData.append('customer_id',customer_id);
        formData.append('bogo_code',bogo_code);
        formData.append('currency_code',currency_code);
        formData.append('currency_rate',currency_rate);
        formData.append('checkout_mode',checkout_mode);
        formData.append('delivery_date',delivery_date);
        formData.append('payment_data',JSON.stringify(payment_data));
        formData.append('hypur_transaction_id',hypur_transaction_id);
		if(typeof change_data!='undefined' && change_data!=null){
			formData.append('change',JSON.stringify(change_data));
		}
		
		formData.append('cart_products',JSON.stringify(cart));
		
		formData.append('auto_apply_discount',cart_totals.auto_apply_discount*currency_rate);
		formData.append('total_tax',cart_totals.tax*currency_rate);
		formData.append('thc_medical',cart_totals.thc_medical);
		formData.append('total_due',cart_totals.total_due);
		formData.append('thc_edible',cart_totals.thc_edible);
		formData.append('thc_non_edible',cart_totals.thc_non_edible);
		formData.append('total_thc',parseFloat((cart_totals.thc_medical+cart_totals.thc_edible+cart_totals.thc_non_edible).toFixed(2)));
		
		formData.append('order_id',this.props.match.params.order_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/place-order',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				setCookie('cart',null);
				setCookie('pos_customer_id',null);
				setCookie('cart_process',null);
				setCookie('currency_code',null);
				setCookie('bogo_code',null);
				setCookie('p_payment_data',null);
				setCookie('change_data',null);
				this.setState({ modalOpenChangePayment:false, modalOpenPrintLabel:true, order_id:data.order_id, success_msg:data.msg });
				/*swal("Success!",data.msg,"success").then(()=>{
					window.location="/app/point-of-sale/customer-lookup";
				});
				setTimeout(()=>{
					window.location="/app/point-of-sale/customer-lookup";
				},4000);*/
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalPrintLabel = () =>{
		window.location="/app/point-of-sale/all-orders";
	}
	
	resetPaymentData = ()  => {	
		const payment_data={
						'1_s' : 0,
						'2_s' : 0,
						'5_s' : 0,
						'10_s' : 0,
						'20_s' : 0,
						'50_s' : 0,
						'100_s' : 0,
						'cents' : 0,
						'nickles' : 0,
						'dimes' : 0,
						'quarters' : 0,
						'50_cents' : 0,
						'1_d' : 0,
						'cash' : 0,
						'card_name' : "",
						'card_number' : "",
						'debit' : 0,
						'discount' : 0,
						'discount_value' : 0,
						's_discount_type' : 1,
						'discount_reason' : "",
						'discount_product' : "",
						'coupon_code' : "",
						'coupon_discount' : 0,
						'points' : 0,
						'point_discount' : 0,
					};
		this.updatePaymentData(payment_data);
	}
	
	payAndCheckout = () =>{
		const cart_totals = this.state.cart_totals;
		if(cart_totals.total_due>0){
			swal("Warning!","Payment is not done","warning");
		}else if(cart_totals.total_due==0){
			this.placeOrderFinal();
		}else{
			this.toggleModalChangePayment()
		}
	}
	
	render() {
		const { customer, currencies, categories, bogo_infos, coupon_infos,	orders, products, cart, cart_totals, payment_data } = this.state;
		
		let data = JSON.parse(JSON.stringify(this.state.cart));
	  
		if (this.state.search) {
			data = data.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Product",
				accessor: "name",
				Cell: (props) => {
					const row = props.original;
					const variations = row.variations;
					var cart_row='';
					var variation_name='';
					if(variations.length){
						let variation = variations.find(r=>{ return row['options']['variation_id']==r['variation_id'] });
						if(variation!=null){
							variation_name = variation.variation_name;
							/*if(variation['stock']<row['qty']){
								if(variation['stock']<=0){
									cart_row=<Fragment><br/><span className="text-danger">Out of Stock. Please Remove this item.</span></Fragment>
								}else{
									cart_row=<Fragment><br/><span className="text-danger">Only {parseInt(variation.stock)} is left in stock</span></Fragment>;
								}
							}*/
						}
					}			
					return(<p className="list-item-heading mt-1">{props.value}{variation_name!='' ? ' ('+variation_name+')':""}{cart_row}</p>)
				}
			},
			{
				Header: "Quantity",
				accessor: "qty",
				width:110,
				Cell:  (props) => <p className="list-item-heading">{props.value}</p>		
			},
			{
				Header: "Price",
				accessor: "unit_price",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat((row['unit_price']*row['qty'])*this.state.currency_rate).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			},	  	  
			{
				Header: "Sales Tax",
				accessor: "sales_tax",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat(row['sales_tax']*this.state.currency_rate).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			},
			{
				Header: "Discount",
				accessor: "discount",				
				Cell: props => {
					const row = props.original;
					if(row.parent_product=="0"){
						const formated_value = number_format(parseFloat(parseFloat((row['discount'])+parseFloat(row['coupon_discount']))*this.state.currency_rate).toFixed(2),2,'.','');
						return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
					}else{
						return "";
					}
				}
			},
			{
				Header: "Amount",
				accessor: "price",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat((row['unit_price']*row['qty']-row['discount']-row['coupon_discount']+row['exise_tax']+row['sales_tax'])*this.state.currency_rate).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol+" "+formated_value}</p>)
				}
			}
		];
		
		
    return (
      <Fragment>
        <BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
		
			<Card className="p-2">
				<div className="d-flex">
					<div className="pos-top-left">
						{customer!=null ? <img alt={customer.vendor_name} src={customer.profile_pic} style={{maxWidth:'80px'}}/>:""}
					</div>
					<div className="pos-top-right">
						<Row className="align-items-center">
							<Colxx lg="5">
								<AvForm
									className="av-tooltip tooltip-right-bottom d-none"
									onSubmit={()=>{}}
								>
									<h5 className="mb-2 text-center"><strong>Today's Conversion Rate</strong></h5>
									<Row className="align-items-center">
										<Colxx lg="3">
											<Label>Choose Currency</Label>
										</Colxx>
										<Colxx lg="4">
											<AvField
												type="select"
												name="currency_code"
												id="currency_code"
												value={this.state.currency_code}
												onChange={(e)=>{ 
													const currency_code = e.target.value;
													const selected_currency = currencies.find(row=>{ return row.currency_code==currency_code });
													var currency_rate = 1;
													var currency_symbol = '$';
													if(selected_currency!=null){
														currency_rate = selected_currency.currency_rate;
														currency_symbol = selected_currency.currency_symbol;
													}
													setCookie('currency_code',currency_code);
													this.setState({ currency_code, currency_rate, currency_symbol },()=>{ this.resetPaymentData(); });
													
												}}
												validate={{
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}}
											>
												{currencies.map(opt=>{
													return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
												})}
											</AvField>
										</Colxx>
										<Colxx lg="5">
											<Label>Currency Rate: {this.state.currency_rate}</Label>
										</Colxx>
									</Row>	
								</AvForm>
							</Colxx>
							<Colxx lg="7">
								<div className="clearfix">
									<div className="float-sm-right ml-2 mr-2 mt-2">
										<h5 className="mb-0"><strong>Change: </strong>{this.state.currency_symbol+" "} {cart_totals.total_due<0 ? number_format(parseFloat(cart_totals.total_due*-1).toFixed(2),2,'.',''):"0"}</h5>
									</div>
									<div className="float-sm-right ml-2 mr-2 mt-2">
										<h5 className="mb-0"><strong>Current Payment: </strong>{this.state.currency_symbol+" "} {payment_data!=null ? number_format(parseFloat(payment_data.cash+payment_data.debit).toFixed(2),2,'.',''):"0"}</h5>
									</div>
									<div className="float-sm-right ml-2 mr-2 mt-2">
										<h5 className="mb-0"><strong>Current Patient Points: </strong>{customer!=null ? customer.price_points:"0"}</h5>
									</div>
								</div>
								<hr className="mt-2 mb-2"/>
								<h5 className="mb-0 text-center"><strong>Unit Conversion Rates</strong> - <span><b>1 MG:</b> 0.001 G </span>, <span><b>1 Oz:</b> 28 G</span></h5>
							</Colxx>
						</Row>
					</div>
				</div>				 
			</Card>
			<Row>
				<Colxx lg="7" >
					<Card className="mt-3">
						<div className="mt-4 mb-1 ml-4 mr-4"> <h3><strong>Order for {customer!=null ? customer.vendor_name:""} <span className="float-sm-right d-none"> (10:18:12)</span></strong></h3></div>
						<div className="mb-4 pt-4 pb-4 pr-2 pl-2 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								minRows = {2}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
						<Row className="text-right">
							<Colxx lg="6">
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"><span className="font-weight-bold">Medical Marijuana </span>: {number_format(parseFloat(cart_totals.thc_medical).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"> <span className="font-weight-bold"> Edibles </span> : {number_format(parseFloat(cart_totals.thc_edible).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold"> Non-Edibles </span> : {number_format(parseFloat(cart_totals.thc_non_edible).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold"> Total THC </span>: {number_format(parseFloat(cart_totals.thc_medical+cart_totals.thc_edible+cart_totals.thc_non_edible).toFixed(2),2,'.','')}</p>
								</div>
							</Colxx>
							<Colxx lg="6">
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"><span className="font-weight-bold">Sub - Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.subtotal*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"> <span className="font-weight-bold">Discount </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.discount*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">TAX </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.tax*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">Grand Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.total*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
							</Colxx>
						 </Row>
					 </Card>
				</Colxx>
				<Colxx lg="5" >
					<Card className="mt-3">
						<div className="mt-4 mb-1 ml-4 mr-4"><h3><strong>Control Center</strong></h3></div>
						<AvForm 
							id="checkoutForm"
							className="error-t-negative pl-4 pr-4">
							<Row className="">
								<Colxx lg="12">									
									<AvGroup className="error-t-negative">
										<Label>Name<span className="text-danger">*</span></Label>
										<AvField
											id="first_name"
											name="first_name"
											type="text"
											value={customer!=null ? customer.vendor_name:""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the name"
												},
												pattern: {
													value: "^[a-zA-Z ,.'-]+$",
													errorMessage: "Invalid name"
												},
												minLength: {
													value: 2,
													errorMessage: "Name must be between 2 and 100 characters"
												},
												maxLength: {
													value: 100,
													errorMessage: "Name must be between 2 and 100 characters"
												}
											}}
										/>
									</AvGroup>										
								</Colxx>
								<Colxx lg="12">									
									<AvGroup className="error-t-negative">
										<Label>Email</Label>
										<AvField
											id="email_id"
											name="email"
											type="email"
											value={customer!=null ? customer.email_id:""}
											autoComplete="off"												
										/>
									</AvGroup>										
								</Colxx>
								
								<Colxx lg="12">									
									<AvGroup className="error-t-negative">
										<Label>Phone Number</Label>
										<AvField
											id="phone"
											name="phone"
											type="text"
											value={customer!=null ? customer.phone:""}
											autoComplete="off"
											validate={{
												number: {
													value: true,
													errorMessage: "Please enter a valid number"
												},
											}}
										/>
									</AvGroup>
								</Colxx>
								
								<Colxx lg="12">									
									<AvGroup className="error-t-negative">
										<Label>Billing Address<span className="text-danger">*</span></Label>
										<AvField
											id="bill_address"
											name="bill_address"
											type="textarea"
											value={customer!=null ? customer.vendor_address:""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the billing address"
												}
											}}
										/>
									</AvGroup>
								</Colxx>
								
								<Colxx lg="12">
									<AvGroup className="error-t-negative">
										<Label>Shipping Address<span className="text-danger">*</span></Label>
										<AvField
											id="ship_address"
											name="ship_address"
											type="textarea"
											value={customer!=null ? customer.vendor_address:""}
											autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter the shipping address"
												}
											}}
										/>
									</AvGroup>
								</Colxx>
								
								<Colxx lg="12">									
									<AvGroup className="error-t-negative">
										<Label>Order Notes</Label>
										<AvField
											id="notes"
											name="notes"
											type="textarea"
											autoComplete="off"
										/>
									</AvGroup>
								</Colxx>
							</Row>
							<Row className="big-btn-font mb-3">
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="success" onClick={this.toggleModalDelivery}>Cash on Delivery</Button>
									</div>
								</Colxx>
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="success" onClick={()=>{ this.setState({ checkout_mode:"Express" },()=>{ this.placeOrderFinal(); }) }}>Express</Button>
									</div>
								</Colxx>
								
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="special-warning" onClick={this.toggleModalCashPayment}>Cash</Button>
									</div>
								</Colxx>
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="special-warning" onClick={this.toggleModalDebit}>Debit/ Credit Order</Button>
									</div>
								</Colxx>
								
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="special-info" onClick={this.toggleModalPricePoints}>Redeem Points</Button>
									</div>
								</Colxx>
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="special-info" onClick={this.toggleModalDiscount}>Discount</Button>
									</div>
								</Colxx>
								
								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="special-info" onClick={this.toggleModalCoupon}>Coupon</Button>
									</div>
								</Colxx>

								<Colxx lg="6">
									<div className="mb-3">
										<Button block type="button" size="sm" color="success" onClick={this.payAndCheckout} >Pay & Check out</Button>
									</div>
								</Colxx>
							
								<Colxx lg="12">
									<div className="mb-3 d-none">
										<Button block disabled={true} type="button" size="sm" color="success" >Pay & Check out with <img src="/assets/img/hypur-logo.png" alt="Hypur" /></Button>
									</div>
								</Colxx>
							</Row>
						</AvForm>
					</Card>					
				</Colxx>
			</Row>
		
			<DeliveryModal
				modalOpen={this.state.modalOpenDelivery}
				toggleModal={this.toggleModalDelivery}
				submitDelivery={this.submitDelivery}
			/>
			
			{this.state.modalOpenCashPayment ? <CashPaymentModal
				modalOpen={this.state.modalOpenCashPayment}
				toggleModal={this.toggleModalCashPayment}
				currency_code={this.state.currency_code}
				currencies={this.state.currencies}
				payment_data={this.state.payment_data}
				total_due={this.state.cart_totals.total_due}
				updatePaymentData = {this.updatePaymentData}
			/>:""}
			
			{this.state.modalOpenChangePayment ? <ChangePaymentModal
				modalOpen={this.state.modalOpenChangePayment}
				toggleModal={this.toggleModalChangePayment}
				currency_code={this.state.currency_code}
				currencies={this.state.currencies}
				change_data={this.state.change_data}
				drawer_data={this.state.drawer_data}
				drawer_id={this.state.drawer_id}
				total_due={this.state.cart_totals.total_due}
				updateChangeData = {this.updateChangeData}
			/>:""}
			
			{this.state.modalOpenDebit ? <DebitPaymentModal
				modalOpen={this.state.modalOpenDebit}
				toggleModal={this.toggleModalDebit}
				currency_code={this.state.currency_code}
				currencies={this.state.currencies}
				payment_data={this.state.payment_data}
				total_due={this.state.cart_totals.total_due}
				updatePaymentData = {this.updatePaymentData}
			/>:""}
			
			{this.state.modalOpenPricePoints ? <RedeemPointModal
				modalOpen={this.state.modalOpenPricePoints}
				toggleModal={this.toggleModalPricePoints}
				price_points={this.state.price_points.filter(r=>{ return parseFloat(r.discount)<=parseFloat(this.state.cart_totals.total) })}
				currency_symbol={this.state.currency_symbol}
				currency_rate={this.state.currency_rate}
				payment_data={this.state.payment_data}
				total_due={this.state.cart_totals.total_due}
				updatePaymentData = {this.updatePaymentData}
				recalculateTotals = {this.recalculateTotals}
			/>:""}
			
			{this.state.modalOpenDiscount ? <DiscountModal
				modalOpen={this.state.modalOpenDiscount}
				toggleModal={this.toggleModalDiscount}
				currency_code={this.state.currency_code}
				currencies={this.state.currencies}
				payment_data={this.state.payment_data}
				sub_total={this.state.cart_totals.subtotal}
				total={this.state.cart_totals.total}
				currency_rate={this.state.currency_rate}
				total_due={this.state.cart_totals.total_due}
				updatePaymentData = {this.updatePaymentData}
				recalculateTotals = {this.recalculateTotals}
			/>:""}
			
			{this.state.modalOpenCoupon ? <CouponModal
				modalOpen={this.state.modalOpenCoupon}
				toggleModal={this.toggleModalCoupon}
				currency_symbol={this.state.currency_symbol}
				currency_rate={this.state.currency_rate}
				payment_data={this.state.payment_data}
				sub_total={this.state.cart_totals.subtotal+this.state.cart_totals.tax}
				customer_id={this.state.customer_id}
				updatePaymentData = {this.updatePaymentData}
				recalculateTotals = {this.recalculateTotals}
			/>:""}
			
			<PrintLabelModal
				modalOpen={this.state.modalOpenPrintLabel}
				toggleModal={this.toggleModalPrintLabel}
				order_id={this.state.order_id}
				modal_head={"Payment has been updated"}
				is_budtender={true}
				success_msg={this.state.success_msg}
			/>
		</BlockUi>	
      </Fragment>
    );
  }
}
