import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input, Table
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {DEFAULT_QTY_TYPES, LBS_CONSTANT } from "../../../../constants/defaultValues";
import Barcode from 'react-barcode';
import moment from 'moment';

const source={'0':'Inventory', '1':'Grow House', '2':'Vendor', '3':'Extrator', '4':'Rotovap', '5':'Distillator','6':'Kitchen','7':'Oven','8':'MCP'}; 
const package_type={'1':'Package', '2':'Finished Product'};

export default class ViewPackageDetailContent extends Component{
	constructor(props) {
		super(props);		
	}
	
		
	render() {
		var qty_type='Grams';
		var quantity='0';
		var barcode='';
		const product_package = this.props.product_package;
		const chain_of_custody = this.props.chain_of_custody;
		
		if(product_package!=null){
			if(product_package['qty_type']=='1'){
				qty_type='Grams';
				quantity = parseFloat(product_package['quantity']).toFixed(2);
			}else if(product_package['qty_type']=='2'){
				qty_type='Lbs';	
				quantity = parseFloat(product_package['quantity']/LBS_CONSTANT).toFixed(2);
			}else{
				qty_type='Units';
				quantity = parseFloat(product_package['quantity']).toFixed(0);
			}

			if(product_package['variation_type']!=null || parseInt(product_package['variation_type'])>-1){
				if(product_package['variation_type']=='0'){
					qty_type='Milligrams';
				}else if(product_package['variation_type']=='1'){
					qty_type='Grams';
				}else if(product_package['variation_type']=='2'){
					qty_type='OZ';	
				}else if(product_package['variation_type']=='3'){
					qty_type='Units';
				}
			}

			if(product_package['source']==5){ 
				barcode=product_package['batch_uid']; }else{ barcode=product_package['barcode']; 
			} 
		}
		return (	  
			<div>
				<Row>
					<Colxx lg="3">
						<Label>Package Id</Label>
					</Colxx>
					<Colxx lg="9">
						<div className="">
							<Barcode value={barcode} height={30} width={0.95} margin={0} marginTop={5} displayValue={false} />
							<p className="text-left">{(barcode.match(/.{1,4}/g)).join(' ')}</p>
						</div>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Available</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['package_type']=='1' ?  quantity+' '+qty_type: parseInt(product_package['quantity'])}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Type</Label>
					</Colxx>
					<Colxx lg="9">
						{package_type[product_package['package_type']]}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Source</Label>
					</Colxx>
					<Colxx lg="9">
						{source[product_package['source']]}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Batch UID</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['batch_uid']}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Added Date</Label>
					</Colxx>
					<Colxx lg="9">
						{moment(product_package['timestamp']*1000).format("MM/DD/YYYY")}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="3">
						<Label>Time In Room</Label>
					</Colxx>
					<Colxx lg="9">
						{product_package['time_in_room']}
					</Colxx>
				</Row>
				
				<h3 className="font-weight-bold mt-3">Chain of Custody</h3>
				<Table>
					<thead>
						<tr>
							<th>Operation</th>  
							<th>Done By</th> 
							<th>Filed Date & Time</th> 
						</tr>
					</thead>
					<tbody>
						{chain_of_custody.map((row,index)=>{
							return(<tr key={index}>
								<td><div dangerouslySetInnerHTML={{ __html: row.operation }} /></td>
								<td>{row.done_by}</td>
								<td>{moment(row.filed_date).format('MM/DD/YYYY HH:mm')}</td>
							</tr>);
						})}
					</tbody>
				</Table>
			</div>
		);
	}
};