import React, {Fragment, Component } from "react";
import {
  Row, Card
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import HeaderCart from "../../components/front-end/HeaderCart";
import MobileMenu from "../../components/front-end/MobileMenu";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import { projectTitle, REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
const dataCrypto = new SimpleCrypto(dataEncryptKey);

class HomeHeader extends Component{
	constructor(props) {
		super(props);

		this.state = {
			configurations: {
				facility_footer_logo:null,
				facility_logo:null
			},
			userCookie: localStorage.getItem('user_cookie_token')
		};
	}
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	render() {	
		const { userCookie, configurations } = this.state;
		const local_user_data = localStorage.getItem('user_data');
	
		return (
		  
		<Row>
			<Colxx xxs="12">
				<Card className="auth-card py-2 main-header-wrapper">
					<div className="container-fluid px-2 px-md-4 ">
						<Row className="w-100">
						    <Colxx xxs="5" sm="3" lg="5" xxl="5" className="d-md-block d-none mob-800" >
								<div className="d-flex  flex-wrap-wrap align-items-center h-100">												
									<ul className="main-page-menu">
										<li><NavLink to={`/`} className="">Home</NavLink></li>
										<li><NavLink to={`/about`} className="">About</NavLink></li>
										<li><NavLink to={`/shop`} className="">Shop</NavLink></li>
										<li><NavLink to={`/deals`} className="">Deals</NavLink></li>
									</ul>									
								</div>								
							</Colxx>
							<Colxx xxs="6" sm="3" lg="2" xxl="2" >
								<div className="d-flex flex-wrap-wrap align-items-center h-100">
									<NavLink to={`/`} className="white d-block text-center w-100">
										{configurations.facility_logo==null ? <img src="/assets/img/logo-black.svg" className="img-responsive" />:<img src={BASE_URL+'pages/'+configurations.facility_logo} className="img-responsive" style={{maxHeight:'50px'}}  />}
									</NavLink>
								</div>
							</Colxx>
							<Colxx xxs="4" sm="4" lg="4" xxl="4" className="d-md-block d-none mob-800" >
								<div className="d-flex menu-home flex-wrap-wrap align-items-center h-100">												
									<ul className="main-page-menu">
										<li><NavLink to={`/contact-us`} className="">Contact Us</NavLink></li>
										{local_user_data ? <li><NavLink to={`/app/dashboard`} className="">My Account</NavLink></li>:<li><NavLink to={`/user/login`} className="">Login</NavLink></li>}
									</ul>									
								</div>								
							</Colxx>
							<Colxx xxs="6" sm="2" lg="1" xxl="1" className="pr-0" >
								<HeaderCart />
							</Colxx>
						</Row>
						{/* mobile menu */}
						<MobileMenu />
					</div>
				</Card>
			</Colxx>
		</Row>
	);
  }
};

export default HomeHeader;
