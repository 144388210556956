import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row,Card,
	CustomInput,
	Button,
	Label,
	InputGroup,
	InputGroupAddon,
	CardHeader,CardBody,
	Nav,NavItem,NavLink, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../helpers/IntlMessages";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddStrainModal from "../../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import TooltipItemText from "../../../components/common/TooltipItemText";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import Barcode from 'react-barcode';

import AllPackages from "../../../components/inventory/EditProduct/AllPackages";
import FinishedPackages from "../../../components/inventory/EditProduct/FinishedPackages";
import Variations from "../../../components/inventory/EditProduct/Variations";
import PricePoints from "../../../components/inventory/EditProduct/PricePoints";
import ViewFileModal from "../../../components/common/ViewFileModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 },
    { label: "Unit", value: "3", key: 2 }
];  
  
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline", 
  "list",
  "bullet",
  "indent", 
];


export default class EditProduct extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeCategory = this.changeCategory.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.getStrainsList = this.getStrainsList.bind(this);
		this.toggleModalViewDoc = this.toggleModalViewDoc.bind(this);
		
		this.state = {		
			modalOpenAdd: false,
			modalOpenViewDoc: false,
			facility:null,
			product:null,
			files:[],
			product_packages:[],
			product_variations:[],
			price_points:[],
			unused_packages:[],
			finished_products:[],
			
			selectStrainType:null,
			selectTaxType:null,
			selectVendorType:null,
			selectAdditivesType:null,
			selectCategoryType:null,
			selectSubCategoryType:null,
			selectPriceGroupType:null,
			selectStrain:[],
			selectTaxCategory:[],
			selectVendor:[],
			selectAdditives:[],
			selectFacilities:[],
			selectRooms:[],
			warehouses:[],
			defaultFaclity:[],
			
			allBrands:[],
			selectedBrand:null,

			auto_apply_yes:false,
			auto_apply_yes_value:1,
			auto_apply_no_value:0,
			auto_apply_no:true,
			selectCategory:[],
			selectSubcategory:[],
			selectPriceGroups:[],
			selectTaxCategory:[],
			selectVendor:[],
			selectAdditives:[],
			category_val:[],
			subcategory_val:[],
			strain_val:[],
			taxcategory_val:[],
			vendor_val:[],
			additive_val:[],
			inclusive_taxes:true,
			shop_status:true,
			is_featured_product:true,
			is_new:false,
			is_top_deal:false,
			selectQuantityType:{ label: "Gram", value: "1", key: 0 },
			textQuillStandart:"",
			blocking:true,
			activeFirstTab:"1",
			product_id:"0",
			doc_url:null,
			user_privileges:[],
		};	
	}
	
	componentDidMount() {

		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
		
		var category=[];
		var subcategory=[];
		var strain=[];
		var taxcategory=[];
		var vendor=[];
		var additive=[];
		var selectRooms=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('product_id',this.props.match.params.id);
        
		axios.post(REST_API_END_POINT+'inventory/get-data-for-edit-product',formData).then((res)=>{
			var data = res.data;
			
			if(data.status===1){
				var product = data.product;
				var facility = data.facility;
				var files = data.files;
				var product_packages = data.packages;
				var product_variations = data.product_variations;
				var price_points = data.price_points;
				var unused_packages = data.unused_packages;
				var finished_products = data.finished_products;
				const product_id = product.id;				
				
				var warehouses = data.warehouses;
				
				category=data.categories;
				subcategory=data.sub_categories;
				strain=data.strains;
				taxcategory=data.tax_categories;
				vendor=data.vendors;
				additive=data.additive_templates;
				selectRooms=data.inventory_rooms;
				let selectPriceGroups=data.grades;
				let allBrands=data.brands;
							
				category = category.map((element,index)=>{
					element.label=element.name;
					element.value=element.category_id;
					element.key=index;
					return element;
				});
				
				subcategory = subcategory.map((element,index)=>{
					element.label=element.name;
					element.value=element.category_id;
					element.key=index;
					return element;
				});
				
				strain = strain.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				taxcategory = taxcategory.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				vendor = vendor.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				additive = additive.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});	
				
				selectRooms = selectRooms.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});

				selectPriceGroups = selectPriceGroups.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});

				allBrands = allBrands.map((element,index)=>{
					element.value=element.id;
					element.key=index;
					return element;
				});	
				
				warehouses = warehouses.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});	

				const selectedBrand = allBrands.find(r=>{ return r.value==product.brand_id });
				const selectCategoryType = category.find(r=>{ return r.value==product.category_id });
				const selectSubCategoryType = subcategory.find(r=>{ return r.value==product.sub_category });
				const selectStrainType = strain.find(r=>{ return r.value==product.strain_name });				
				const defaultFaclity = product.facility_id.split(",");
				const selectRoomType = selectRooms.find(r=>{ return r.value==product.room_id });
				const selectQuantityType = selectQuantity.find(r=>{ return r.value==product.qty_type });				
				const selectTaxType = taxcategory.find(r=>{ return r.value==product.tax_category });
				const selectVendorType = vendor.find(r=>{ return r.value==product.vendor_id });
				const selectAdditivesType = additive.find(r=>{ return r.value==product.additive_template_id });
				const selectPriceGroupType = selectPriceGroups.find(r=>{ return r.value==product.grade });
				const inclusive_taxes = product.inclusive_taxes=="1";
				const shop_status = product.shop_status=="1";
				const is_featured_product = product.is_featured_product=="1";
				const is_new = product.is_new=="1";
				const is_top_deal = product.is_top_deal=="1";
				const textQuillStandart = product.description;
				
				this.setState({
					selectCategory:category,
					selectSubcategory:subcategory,	
					selectStrain:strain,
					selectTaxCategory:taxcategory,
					selectVendor:vendor,
					selectAdditives:additive,				
					selectFacilities:data.inventory_facilities,	
					selectRooms:selectRooms,
					selectPriceGroups,
					defaultFaclity:[facility_id],
					
					product,
					product_id,
					facility,
					files,
					product_packages,
					product_variations,
					price_points,
					unused_packages,
					finished_products,
					warehouses,
					allBrands,
					
					selectedBrand,
					selectCategoryType,
					selectSubCategoryType,
					selectStrainType,
					defaultFaclity,
					selectRoomType,
					selectQuantityType,
					selectTaxType,
					selectVendorType,
					selectAdditivesType,
					selectPriceGroupType,
					inclusive_taxes,
					shop_status,
					is_featured_product,
					is_new,
					is_top_deal,
					textQuillStandart,
					blocking:false
				});
			}else{
				window.location="/app/inventory/inventory";
			}
						
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}
	
	getStrainsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-strains-list',formData).then((res)=>{
			var data = res.data;
			var selectStrainType = this.state.selectStrainType;
			
			var strains=data.strains;
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(strains.length){
				selectStrainType=strains[0];
			}
				
			this.setState({			
				selectStrain:strains,
				selectStrainType
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	changeCategory(selectCategoryType) {		
		var subcategory=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('category_id',selectCategoryType.value);
        
		axios.post(REST_API_END_POINT+'inventory/get-sub-categories',formData).then((res)=>{
			var data = res.data;
		
			subcategory=data.sub_categories;
			
			subcategory = subcategory.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectSubcategory:subcategory,
				selectSubCategoryType:null,
				selectCategoryType
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			let errorMessage='';
			if(this.state.selectedBrand==null){
				swal("Warning!","Please choose a brand","warning");
				return false;
			}
						
			if(this.state.selectCategoryType==null){
				swal("Warning!","Please choose category","warning");
				return false;
			}
						
			if(this.state.selectTaxType==null){
				errorMessage="Please choose tax category";
			}
			
			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			this.setState({ blocking:true });
			
			document.getElementById("edit-product").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("inventoryEditForm"));
			formData.append('user_id',user_id);
			formData.append('inclusive_taxes',this.state.inclusive_taxes ? 1:0);
			formData.append('shop',this.state.shop_status ? 1:0);
			formData.append('is_featured_product',this.state.is_featured_product ? 1:0);
			formData.append('is_new',this.state.is_new ? 1:0);
			formData.append('is_top_deal',this.state.is_top_deal ? 1:0);
			formData.append('description',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'inventory/edit-product/'+this.state.product_id,formData).then((res)=>{
				var data = res.data;
								
				document.getElementById("edit-product").disabled=false;
				
				this.setState({ blocking:false,  });
				swal("Success",data.msg,"success");
				
				setTimeout(function(){ window.location.reload() }, 1000);
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-product").disabled=false;
				this.setState({ blocking:false,  });
			});

		}
	}
  
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalViewDoc = (e,doc_url=null) => {
		this.setState({
			modalOpenViewDoc: !this.state.modalOpenViewDoc,
			doc_url
		});
	};
	
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	onMultiFileChange = e => {
		switch (e.target.name){
			case 'files[]':
			if(e.target.files[0]){
				var filenames_obj=e.target.files;
				var filenames=[];
				for (var i = 0; i < filenames_obj.length; i++) {
					filenames.push(filenames_obj[i].name);
				}
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=filenames.join(',');
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}
	};
	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('product_id',this.state.product_id);
        
		axios.post(REST_API_END_POINT+'inventory/data-for-product-packages',formData).then((res)=>{
			var data = res.data;			
			if(data.status===1){
				var product_packages = data.packages;
				var product_variations = data.product_variations;
				var unused_packages = data.unused_packages;
				var finished_products = data.finished_products;
				var price_points = data.price_points;
				
				var warehouses = data.warehouses;

				warehouses = warehouses.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});			
				
				this.setState({
					product_packages,
					product_variations,
					unused_packages,
					finished_products,
					price_points,
					warehouses
				});
			}			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	deleteFile = (id)  => {	
        swal({
          title: "Are you sure?",
          text: "Delete the product document?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);	
				
				var formData = new FormData();							
				formData.append('facility_id',facility_id);			
				formData.append('id',id);
				formData.append('user_id',user_id);
				
				axios.post(REST_API_END_POINT+'inventory/delete-file-attachment',formData).then((res)=>{
					var data = res.data;					
					swal("Success",data.msg,"success");
					this.setState({ files:data.files })
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("delete-product-variations").disabled=false;
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

  render() {


 
	const {  selectCategory,selectSubcategory,selectStrain,selectTaxCategory,selectVendor,selectAdditives, selectFacilities, selectRooms, selectPriceGroups, defaultFaclity, allBrands, product } = this.state;
	var product_type=1;
	if(product!=null){
		product_type=parseInt(product.product_type);
	}
	
	  return (
	  
		<Fragment>
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
			
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.edit-product" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>	
				<Card className="p-4">
					<Row>			  
					  <Colxx xxs="12">
							<div id="api_message"></div>
							<AvForm
								className="av-tooltip tooltip-right-bottom"
								id="inventoryEditForm"
								onSubmit={this.handleSubmit}
							  >		 
								{product!=null ?<Row>
									<Colxx lg="12">
										<div className="text-center float-left">
											<Barcode value={product.barcode} height={40} displayValue={false} />
											<p className="h5">{(product.barcode.match(/.{1,4}/g)).join(' ')}</p>
										</div>
									</Colxx>
								</Row>:""}
								
								<Row>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
										  <Label>Name<span className="text-danger">*</span></Label>
										  <AvField							
											name="name"
											type="text"
											autoComplete="off"
											value={product!=null ? product.name:""}
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },
											  minLength: {
												value: 2,
												errorMessage: "The name must be between 2 and 200 characters"
											  },
											  maxLength: {
												value: 200,
												errorMessage: "The name must be between 2 and 200 characters"
											  }
											}}
										  />
										</AvGroup>
									</Colxx>					
								</Row>
								<Row>							
									<Colxx lg="12">
										<span className="h8 d-block">Description <TooltipItemText key="tip_des" item={{
										  className:'mr-1 mb-2 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Description will not be added to weedmap.</p>
										}} id="tip_des" /></span>								
									</Colxx>											
									<Colxx lg="12"  className="mb-2">											
										<AvGroup className="error-t-negative">											
											<ReactQuill
												theme="snow"
												value={this.state.textQuillStandart}
												onChange={this.handleChangeQuillStandart}
												placeholder=""
												modules={quillModules}
												formats={quillFormats}
											/>
										</AvGroup>
									</Colxx>
								</Row>
								
								<Row>
									<Colxx lg="12" className="mt-2 mb-2">
										<Label>Product Type</Label> &nbsp;&nbsp;							
										<strong>{product!=null ? (parseInt(product.product_type)===1 ? "Cannabis":"Non-Cannabis"):""}</strong>
										<p className="text-danger mb-0">Please contact administrator to change product type</p>
									</Colxx>
								</Row>
								
								<Row className="mt-3">
									<Colxx lg="12">
										<Label >Brand<span className="text-danger">*</span></Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="brand_id"
											id="brand_id"
											value={this.state.selectedBrand}						
											options={allBrands}
											onChange={selectedBrand => { this.setState({ selectedBrand }) }}
										/>
									</Colxx>					
								</Row>
								
								<Row className="mt-3">
									<Colxx lg="6">
										<Label className="d-block">Category<span className="text-danger">*</span> <TooltipItemText key="tip_category" item={{
										  className:'mr-1 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Only the products in following categories will be added to weedmap.<br/>
											<ul style={{paddingLeft:'20px'}}><li>Concentrates</li><li>Cultivation</li><li>Edibles</li><li>Flower</li><li>Gear</li><li>Topicals</li><li>Vape Pens</li></ul></p>
										}} id="tip_category" /></Label>
										
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="category"
											id="category"
											value={this.state.selectCategoryType}						
											options={selectCategory}
											onChange={(selectCategoryType)=>{ this.changeCategory(selectCategoryType) }}
										/>
									</Colxx>
									<Colxx lg="6">
										<Label >Sub Category</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="sub_category"
											id="sub_category"
											placeholder="--Select Sub Category--"
											value={this.state.selectSubCategoryType}						
											options={selectSubcategory}
											onChange={selectSubCategoryType => { this.setState({ selectSubCategoryType }) }}
										/>
									</Colxx>					
								</Row>
								{product!=null ? (product.thumbnail!=null ? <Row className="mt-4">
									<Colxx lg="12" >
										<div className="d-flex justify-content-start">
											<div className="text-center img-view-wrapper">
												<img src={BASE_URL+'products/'+product.thumbnail} alt="Product Image" className="img-responsive" />
											</div>
										</div>
									</Colxx>
								</Row>:""):""}
								
								<Row className="mt-4">
									<Colxx lg="12" >						
										<AvGroup className="error-l-125 error-t-negative">
											<Label>Thumbnail</Label>
											<InputGroup className="mb-3">
											  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
											  <CustomInput
												type="file"
												id=""
												name="thumbnail"
												accept="image/*"
												onChange={this.onChange}
											  />
											</InputGroup>
										</AvGroup>					
									</Colxx>
								</Row>
								<Row className={product!=null ? (parseInt(product.product_type)===2 ? "d-none":""):""}>
									<Colxx lg="6">
										<Label className="d-block">Strain <TooltipItemText key="tip_str" item={{
										  className:'mr-1 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Strain will not be added to weedmap.</p>
										}} id="tip_str" /></Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="strain"
											id="strain"
											placeholder="--Select Strain--"
											value={this.state.selectStrainType}						
											options={selectStrain}
											onChange={selectStrainType => { this.setState({ selectStrainType }) }}					
										/>
									</Colxx>
									{this.state.user_privileges.indexOf(17)!==-1 ? <Colxx lg="6">
										<Button
											color="primary"
											size="sm"
											className=" mt-4"
											onClick={this.toggleModalAdd}>
											Add Strain
										</Button>
									</Colxx>:""}					
								</Row>
								{selectFacilities.length > 0 ? <Row className="mt-2 pt-2">
									<Colxx lg="12">
										<Label >Facilities</Label>				
										<AvGroup className="w-100 mb-0">
											<AvCheckboxGroup
												inline
												name="facility_id[]"
												onChange={selectFacility => { this.setState({ selectFacility }) }}
												validate={{
												  required: {
													value: true,
													errorMessage: "Please select any of the facilities "
												  }
												}}
												defaultValue={defaultFaclity}
											>
												{selectFacilities.map((row)=>{
													return <AvCheckbox customInput label={row.facility_name} value={row.facility_id} key={row.facility_id} />
												})}
											</AvCheckboxGroup >	
										</AvGroup>
									</Colxx>					
								</Row>:""}
								<Row className="mt-2">
									<Colxx lg="12">
										<Label>Room</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="room_id"
											id="room_id"
											value={this.state.selectRoomType}						
											options={selectRooms}
											onChange={selectRoomType => { this.setState({ selectRoomType }) }}
										/>
									</Colxx>									
								</Row>
								
								{this.state.selectCategoryType!=null ? <Row className="mt-3">
									{this.state.selectCategoryType.aromas=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Aromas</Label>
											<AvField
												id="aromas"
												name="aromas"
												type="text"
												value={product!=null ? product.aromas:""}
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.flavors=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Flavors</Label>
											<AvField
												id="flavors"
												name="flavors"
												type="text"
												value={product!=null ? product.flavors:""}
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.effects=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Effects</Label>
											<AvField
												id="effects"
												name="effects"
												type="text"
												value={product!=null ? product.effects:""}
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.symptoms=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Symptoms</Label>
											<AvField
												id="symptoms"
												name="symptoms"
												type="text"
												value={product!=null ? product.symptoms:""}
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.thc=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>THC</Label>
											<AvField
												id="thc"
												name="thc"
												type="number"
												value={product!=null ? product.thc:""}
												min="0"
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.cbd=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>CBD</Label>
											<AvField
												id="cbd"
												name="cbd"
												type="number"
												value={product!=null ? product.cbd:""}
												min="0"
											/>
										</AvGroup>
									</Colxx>:""}
									
									{this.state.selectCategoryType.cannabinoids=='Y' ? <Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Cannabinoids</Label>
											<AvField
												id="cannabinoids"
												name="cannabinoids"
												type="number"
												value={product!=null ? product.cannabinoids:""}
												min="0"
											/>
										</AvGroup>
									</Colxx>:""}
								</Row>:""}
								
								<Row className="mt-2">
									<Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Quantity Unit<span className="text-danger">*</span></Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="qty_type"
												id="qty_type"
												value={this.state.selectQuantityType}						
												options={selectQuantity}
												onChange={selectQuantityType => { this.setState({ selectQuantityType }) }}
												required={true}
										   />
										</AvGroup>
									</Colxx>	
									<Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Tax Category<span className="text-danger">*</span></Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="tax_category"
												id="tax_category"
												placeholder="--Select Tax Category--"
												value={this.state.selectTaxType}						
												options={selectTaxCategory}
												onChange={selectTaxType => { this.setState({ selectTaxType }) }}
												required={true}
											/>
										</AvGroup>
									</Colxx>					
								</Row>
								<Row className="mt-1">
									<Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Vendor</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="vendor_id"
												id="vendor_id"
												placeholder="--Select Vendor--"
												value={this.state.selectVendorType}						
												options={selectVendor}
												onChange={selectVendorType => { this.setState({ selectVendorType }) }}
											/>
										</AvGroup>
									</Colxx>	
									<Colxx lg="6">
										<AvGroup className="error-t-negative">
											<Label>Additives</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="additive_template_id"
												id="additive_template_id"
												placeholder="--Select Additives--"
												value={this.state.selectAdditivesType}						
												options={selectAdditives}
												onChange={selectAdditivesType => { this.setState({ selectAdditivesType }) }}
											/>
										</AvGroup>
									</Colxx>					
								</Row>
								<Row className="mt-1">
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>Price Group</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="grade"
												id="grade"
												placeholder="--Select Price Group --"
												value={this.state.selectPriceGroupType}						
												options={selectPriceGroups}
												onChange={selectPriceGroupType => { this.setState({ selectPriceGroupType }) }}
											/>
										</AvGroup>
									</Colxx>				
								</Row>
								<Row>
									<Colxx lg="12" className="mb-2">						
										<AvGroup className="error-l-125 error-t-negative">
											<Label>Documents</Label>
											<InputGroup className="mb-3">
											  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
											  <CustomInput
												type="file"
												name="files[]"
												id="test_document"
												accept="image/*"
												multiple 
												onChange={this.onMultiFileChange}
											  />
											</InputGroup>
										</AvGroup>
										{this.state.files.length ? <Row>
											<Colxx lg="8" className="mb-2">	
												<table role="presentation" className="table table-striped">
													<tbody className="files">
														{this.state.files.map((doc,i)=>{
															return(<tr className="template-download" key={i}>
																<td>												
																	<p className="name"><a className="open-document cursor-pointer" onClick={(e)=>{ this.toggleModalViewDoc(e,BASE_URL+'products/'+doc['upload_file_name']) }}>{doc['upload_file_name']}</a></p>
																</td>
																<td>
																	<Button type="button" color="danger" size="sm" onClick={()=>{ this.deleteFile(doc.ID) }} >
																		<i className="fa fa-trash"></i>{" "}<span className="ml-1">Delete</span>
																	</Button>
																</td>
															</tr>)
														})}
													</tbody>
												</table>
											</Colxx>
										</Row>:""}
									</Colxx>
								</Row>
								<Row>
									<Colxx lg="12" className="mb-2">
										<Label>													  
											<Switch
												className="custom-switch custom-switch-primary im-inline-block"
												checked={this.state.inclusive_taxes}
												onChange={status => { this.setState({ inclusive_taxes:!this.state.inclusive_taxes }) }}
											/>
											<span className="h6 ml-4">Inclusive Taxes</span>
										</Label>
									</Colxx>
								</Row>
								<Row>
									<Colxx lg="6" className="mb-2">
										<Label>													  
											<Switch
												className="custom-switch custom-switch-primary im-inline-block"
												checked={this.state.shop_status}
												onChange={status => { this.setState({ shop_status:!this.state.shop_status }) }}
											/>
											<span className="h6 ml-4">Add to Shop</span>
										</Label>
									</Colxx>
									<Colxx lg="6"  className="mb-2">
										<Label>													  
											<Switch
												className="custom-switch custom-switch-primary im-inline-block"
												checked={this.state.is_featured_product}
												onChange={status => { this.setState({ is_featured_product:!this.state.is_featured_product }) }}		
											/>
											<span className="h6 ml-4">Featured Product?</span>
										</Label>					
									</Colxx>
								</Row>
								
								<Row>
									<Colxx lg="6" className="mb-2">
										<Label>													  
											<Switch
												className="custom-switch custom-switch-primary im-inline-block"
												checked={this.state.is_new}
												onChange={status => { this.setState({ is_new:!this.state.is_new }) }}
											/>
											<span className="h6 ml-4">Is New Arrival?</span>
										</Label>
									</Colxx>
									<Colxx lg="6"  className="mb-2">
										<Label>													  
											<Switch
												className="custom-switch custom-switch-primary im-inline-block"
												checked={this.state.is_top_deal}
												onChange={status => { this.setState({ is_top_deal:!this.state.is_top_deal }) }}								
											/>
											<span className="h6 ml-4">Is Top Deal Product?</span>
										</Label>					
									</Colxx>
								</Row>
								
								<hr/>
								<Button color="primary" id="edit-product" className="mr-2">
									<IntlMessages id="customer.save" />
								</Button>
								<Button color="secondary" type="button" outline onClick={()=>{ window.location="/app/inventory/inventory" }}>
									<IntlMessages id="customer.cancel" />
								</Button>
							</AvForm>
							
							<AddStrainModal
								modalOpen={this.state.modalOpenAdd}
								toggleModal={this.toggleModalAdd}
								dataListRender={this.getStrainsList}
							  />
						</Colxx>
					</Row>
				</Card>
				
				{product!=null ? <Row>
					<Colxx lg="12" className="page-tabs">
						<Card className="p-4 mt-4">								
							<CardHeader>
								<Nav tabs className="card-header-tabs ">
									{product_type===1 ? <NavItem className="cursor-pointer">
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "1",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("1");
											}}
											to="#"
										>
											All Packages
										</NavLink>
									</NavItem>:""}
									<NavItem className="cursor-pointer">
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === (product_type===1 ? "2":"1"),
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab(product_type===1 ? "2":"1");
											}}
											to="#"
										>
											Finished Products
										</NavLink>
									</NavItem>
									<NavItem className="cursor-pointer">
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "3",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("3");
											}}
											to="#"
										>
											Variations
										</NavLink>
									</NavItem>
									<NavItem className="cursor-pointer">
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "4",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("4");
											}}
											to="#"
										>
											Price Points
										</NavLink>
									</NavItem>						
								</Nav>
							</CardHeader>
							<CardBody>		
								{product_type===1 ? <TabContent activeTab={this.state.activeFirstTab}>
								  <TabPane tabId="1">
										<AllPackages 
											product_id={this.state.product_id} 
											product={product} 
											dataListRender={this.dataListRender}
											product_packages={this.state.product_packages}
											warehouses={this.state.warehouses}
											facility={this.state.facility}
										/>
								  </TabPane>
								</TabContent>:""}
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId={product_type===1 ? "2":"1"}> 
										<FinishedPackages 
											product_id={this.state.product_id} 
											product={product} 
											dataListRender={this.dataListRender}
											finished_products={this.state.finished_products}
											product_variations={this.state.product_variations}
											unused_packages={this.state.unused_packages}
											facilities={this.state.selectFacilities}
											warehouses={this.state.warehouses}
											facility={this.state.facility}
										/>
									</TabPane>
								</TabContent>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="3">
										<Variations 
											product_id={this.state.product_id} 
											product={product} 
											dataListRender={this.dataListRender}
											product_variations={this.state.product_variations}
											sub_categories={selectSubcategory}
											facility={this.state.facility}
										/>
									</TabPane>
								</TabContent>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="4">
										<PricePoints 
											product_id={this.state.product_id} 
											product={product} 
											dataListRender={this.dataListRender}
											price_points={this.state.price_points}
											facility={this.state.facility}
										/>
									</TabPane>
								</TabContent>
							</CardBody>	
						</Card>	
					</Colxx>
				</Row>:""}
			</BlockUi>	
			{this.state.doc_url!=null ? <ViewFileModal 
				modalOpen={this.state.modalOpenViewDoc}
				toggleModal={this.toggleModalViewDoc}
				doc_url={this.state.doc_url}
			/>:""}
		</Fragment>
	  );
  }
};