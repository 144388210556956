import PropTypes from "prop-types";
import React from "react";
import ShopSearch from "./product/ShopSearch";
import ShopCategories from "./product/ShopCategories";

const ShopSidebar = ({ uniqueCategories, setCategoryParams, shopSearchSubmit, sideSpaceClass }) => {

  return (
    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      <ShopSearch shopSearchSubmit={shopSearchSubmit} />

      {/* filter by categories */}
      <ShopCategories
        categories={uniqueCategories}
        setCategoryParams={setCategoryParams}
      />
	  
    </div>
  );
};

ShopSidebar.propTypes = {
  setCategoryParams: PropTypes.func,
  uniqueCategories: PropTypes.array,
  sideSpaceClass: PropTypes.string
};

export default ShopSidebar;
