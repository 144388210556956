import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import CheckedInMenu from "../../../components/pos/CheckedInMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerTypes = [
	{ label: "All", value: "0", key: 0 },
    { label: "Wholesalers", value: "1", key: 1 },
    { label: "Retailers", value: "2", key: 2 },
    
];
export default class Directory extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customers: [],
			allCustomers: [],
			search: '',
			customerTypes:null
		};

		this.checkIn = this.checkIn.bind(this);
		this.filterCustomer=this.filterCustomer.bind(this);
	}

	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'point-of-sale/get-customer-lookup', formData).then((res) => {
			var data = res.data;

			if (data.customers) {
				var customers = data.customers;
				this.setState({
					customers: customers,
					allCustomers:customers,
				});
			} else {
				this.setState({
					customers: [],
					allCustomers:[],
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	checkIn(customer_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('customer_id', customer_id);

		axios.post(REST_API_END_POINT + 'point-of-sale/checkin', formData).then((res) => {
			var data = res.data;

			if (data.status == 1) {
				window.location = "/app/point-of-sale/pos/" + customer_id;
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}
	filterCustomer(customerType)
	{
		let data = this.state.allCustomers;
		// data = data.filter(row => {
		// 	return (row.customerType==customerType.value)
		// })
		if(customerType.value!=0){
			data = data.filter(item => item.register_type === customerType.value);
		}
	
		
		console.log('Nush#####',customerType.value);
		
		this.setState({customerType,
		customers:data})
	}

	render() {
		let data = this.state.customers;
		if (this.state.search) {
			data = data.filter(row => {
				return row.vendor_name.toLowerCase().includes(this.state.search) || row.barcode.includes(this.state.search) || row.license.includes(this.state.search)
			})
		}

		const dataTableColumns = [
			{
				Header: "ID",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading mb-0">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "License",
				accessor: "license",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Check In",
				accessor: "check_in",
				Cell: props => <Fragment> <Button color="success" size="xs" className="mb-2" onClick={() => { this.checkIn(props.original.vendor_id) }}  >{props.value == '' ? "Check In" : <img src={props.value} className="img-responsive" />}</Button></Fragment>
			}
		];
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.customer-lookup" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>

				<Row>
					<Colxx md="8">
						<Card>
							<CardBody>
								<div className="mb-4 dropdown-edit">
									<FormGroup row>
										<Colxx lg="6">
											<Input
												type="text"
												name="table_search"
												id="table_search"
												placeholder="Search"
												value={this.state.search}
												onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
												
											/>
										</Colxx>
										<Colxx lg="3 " style={{marginLeft:"20%",marginTop:"-1%"}}>
											<Label className="mb-0" >Sort By</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"
												name="customer_type"
												id="customer_type"
												value={this.state.customerType}
												options={customerTypes}
												// onChange={customerType => { this.setState({ customerType }) }}
												onChange={customerType => { this.filterCustomer( customerType ) }}
											/>
										</Colxx>
									</FormGroup>

									<Row>
										<Colxx lg="12">
											<ReactTable
												data={data}
												columns={dataTableColumns}
												defaultPageSize={10}
												filterable={false}
												showPageJump={false}
												PaginationComponent={DataTablePagination}
												showPageSizeOptions={true}
												defaultSorted={[{ id: "send_on", desc: true }]}
											/>
										</Colxx>
									</Row>
								</div>
							</CardBody>
						</Card>
					</Colxx>
				</Row>

				<CheckedInMenu />
			</Fragment>
		);
	}
}
