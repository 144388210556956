import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import TooltipItemText from "../../components/common/TooltipItemText";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 },
    { label: "Unit", value: "3", key: 2 }
];
  
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline", 
  "list",
  "bullet",
  "indent", 
];

export default class AddProductLabelModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeCategory = this.changeCategory.bind(this);
		this.getStrainsList = this.getStrainsList.bind(this);
		
		this.state = {		
			modalOpenAdd: false,
			selectStrainType:null,
			selectTaxType:null,
			selectVendorType:null,
			selectAdditivesType:null,
			selectCategoryType:null,
			selectSubCategoryType:null,
			selectPriceGroupType:null,
			selectAdditives:[],
			selectPriceGroups:[],
			selectFacilities:[],
			selectRooms:[],
			defaultFaclity:[],
			
			allBrands:[],
			selectedBrand:null,

			product_type:"1",
			auto_apply_yes:false,
			auto_apply_yes_value:1,
			auto_apply_no_value:0,
			auto_apply_no:true,
			selectCategory:[],
			selectSubcategory:[],
			selectStrain:[],
			selectTaxCategory:[],
			selectVendor:[],
			category_val:[],
			subcategory_val:[],
			strain_val:[],
			taxcategory_val:[],
			vendor_val:[],
			additive_val:[],
			inclusive_taxes:true,
			shop_status:true,
			is_featured_product:true,
			is_new:false,
			is_top_deal:false,
			selectQuantityType:{ label: "Gram", value: "1", key: 0 },
			textQuillStandart:""
		};	
	}
	
	componentDidMount() {		
		var category=[];
		var subcategory=[];
		var strain=[];
		var taxcategory=[];
		var vendor=[];
		var additive=[];
		var selectRooms=[];
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-data-for-add-product',formData).then((res)=>{
			var data = res.data;
		
			category=data.categories;
			subcategory=data.sub_categories;
			strain=data.strains;
			taxcategory=data.tax_categories;
			vendor=data.vendors;
			additive=data.additive_templates;
			selectRooms=data.inventory_rooms;
			let selectPriceGroups=data.grades;
			let allBrands=data.brands;

			category = category.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			subcategory = subcategory.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			strain = strain.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			taxcategory = taxcategory.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			vendor = vendor.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			additive = additive.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});

			selectRooms = selectRooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			selectPriceGroups = selectPriceGroups.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			allBrands = allBrands.map((element,index)=>{
				element.value=element.id;
				element.key=index;
				return element;
			});				
			
			this.setState({
				selectCategory:category,
				selectSubcategory:subcategory,	
                selectStrain:strain,
                selectTaxCategory:taxcategory,
                selectVendor:vendor,
                selectAdditives:additive,				
                selectFacilities:data.inventory_facilities,	
                selectRooms:selectRooms,	
				defaultFaclity:[facility_id.toString()],
				selectPriceGroups,
				allBrands
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	getStrainsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-strains-list',formData).then((res)=>{
			var data = res.data;
			var selectStrainType = this.state.selectStrainType;
			
			var strains=data.strains;
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(strains.length){
				selectStrainType=strains[0];
			}
				
			this.setState({			
				selectStrain:strains,
				selectStrainType
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	changeCategory(selectCategoryType) {		
		var subcategory=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('category_id',selectCategoryType.value);
        
		axios.post(REST_API_END_POINT+'inventory/get-sub-categories',formData).then((res)=>{
			var data = res.data;
		
			subcategory=data.sub_categories;
			
			subcategory = subcategory.map((element,index)=>{
				element.label=element.name;
				element.value=element.category_id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectSubcategory:subcategory,
				selectSubCategoryType:null,
				selectCategoryType
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			if(this.state.selectedBrand==null){
				swal("Warning!","Please choose a brand","warning");
				return false;
			}
			
			if(this.state.selectCategoryType==null){
				swal("Warning!","Please choose category","warning");
				return false;
			}
			
			let errorMessage='';
			
			if(this.state.selectTaxType==null){
				errorMessage="Please choose tax category";
			}
		
			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("add-product").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("inventoryForm"));
			formData.append('user_id',user_id);
			formData.append('inclusive_taxes',this.state.inclusive_taxes ? 1:0);
			formData.append('shop',this.state.shop_status ? 1:0);
			formData.append('is_featured_product',this.state.is_featured_product ? 1:0);
			formData.append('is_new',this.state.is_new ? 1:0);
			formData.append('is_top_deal',this.state.is_top_deal ? 1:0);
			formData.append('description',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'inventory/add-product',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-product").disabled=false;
				this.setState({ 
					selectedBrand:null,
					selectStrainType:null,
					selectTaxType:null,
					selectVendorType:null,
					selectAdditivesType:null,
					selectCategoryType:null,
					selectSubCategoryType:null,
					selectPriceGroupType:null,
				})
				this.props.toggleModal();
				this.props.dataListRender();
				
				this.setState({ textQuillStandart:"", shop_status:true, is_featured_product:true,	selectQuantityType:{ label: "Gram", value: "1", key: 0 },  })
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-product").disabled=false;
			});

		}
	}
  
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	
	onMultiFileChange = e => {
		switch (e.target.name){
			case 'files[]':
			if(e.target.files[0]){
				var filenames_obj=e.target.files;
				var filenames=[];
				for (var i = 0; i < filenames_obj.length; i++) {
					filenames.push(filenames_obj[i].name);
				}
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=filenames.join(',');
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}
	};

  render() {
 
		const {  selectCategory,selectSubcategory,selectStrain,selectTaxCategory,selectVendor,selectAdditives, selectFacilities, selectRooms, selectPriceGroups, defaultFaclity, allBrands } = this.state;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add Product Label
			</ModalHeader>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				id="inventoryForm"
				onSubmit={this.handleSubmit}
			>
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField							
							name="name"
							type="text"
							autoComplete="off"
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},
								minLength: {
									value: 2,
									errorMessage: "The name must be between 2 and 200 characters"
								},
								maxLength: {
									value: 200,
									errorMessage: "The name must be between 2 and 200 characters"
								}
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>							
					<Colxx lg="12">
						<span className="h8 d-block">Description <TooltipItemText key="tip_des" item={{
										  className:'mr-1 mb-2 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Description will not be added to weedmap.</p>
										}} id="tip_des" /> </span>								
					</Colxx>											
					<Colxx lg="12"  className="mb-2">											
						<AvGroup className="error-t-negative">											
							<ReactQuill
							  theme="snow"
							  value={this.state.textQuillStandart}
							  onChange={this.handleChangeQuillStandart}
							  placeholder=""
							  modules={quillModules}
							  formats={quillFormats}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" >
						<Label>Product Type<span className="text-danger">*</span></Label> &nbsp;&nbsp;							
						<AvRadioGroup
							inline
							name="product_type"
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the product type"
							  }
							}}
							value={this.state.product_type}							
						>								
							<AvRadio customInput label="Cannabis" value="1" onClick={(e)=>{	this.setState({ product_type:e.target.value });	}} />
							<AvRadio customInput label="Non-Cannabis" value="2" onClick={(e)=>{	this.setState({ product_type:e.target.value, selectStrainType:null });	}} />
						</AvRadioGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<Label >Brand<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="brand_id"
							id="brand_id"
							value={this.state.selectedBrand}						
							options={allBrands}
							onChange={selectedBrand => { this.setState({ selectedBrand }) }}
						/>
					</Colxx>					
				</Row>
				<Row className="mt-4">
					<Colxx lg="6">
						<Label className="d-block">Category<span className="text-danger">*</span> <TooltipItemText key="tip_category" item={{
										  className:'mr-1 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Only the products in following categories will be added to weedmap.<br/>
											<ul style={{paddingLeft:'20px'}}><li>Concentrates</li><li>Cultivation</li><li>Edibles</li><li>Flower</li><li>Gear</li><li>Topicals</li><li>Vape Pens</li></ul></p>
										}} id="tip_category" /></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="category"
							id="category"
							value={this.state.selectCategoryType}						
							options={selectCategory}
							onChange={(selectCategoryType)=>{ this.changeCategory(selectCategoryType) }}
						/>
					</Colxx>
					<Colxx lg="6">
						<Label >Sub Category</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="sub_category"
							id="sub_category"
							placeholder="--Select Sub Category--"
							value={this.state.selectSubCategoryType}						
							options={selectSubcategory}
							onChange={selectSubCategoryType => { this.setState({ selectSubCategoryType }) }}
						/>
					</Colxx>					
				</Row>
				<Row className="mt-4">
					<Colxx lg="12" className="mb-2">						
						<AvGroup className="error-l-125 error-t-negative">
							<Label>Thumbnail</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									id=""
									name="thumbnail"
									accept="image/*"
									onChange={this.onChange}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>
				</Row>
				<Row className={this.state.product_type=="2" ? "d-none":""}>
					<Colxx lg="6">
						<Label className="d-block">Strain <TooltipItemText key="tip_str" item={{
										  className:'mr-1 btn btn-primary btn-xs round-tip float-right',
										  placement: "bottom",
										  text: "?",
										  body: <p className="text-left">Strain will not be added to weedmap.</p>
										}} id="tip_str" /></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="strain"
							id="strain"
							placeholder="--Select Strain--"
							value={this.state.selectStrainType}						
							options={selectStrain}
							onChange={selectStrainType => { this.setState({ selectStrainType }) }}					
						/>
					</Colxx>
					{this.props.user_privileges.indexOf(17)!==-1 ? <Colxx lg="6">
						<Button
							color="primary"
							size="sm"
							className=" mt-4"
							onClick={this.toggleModalAdd}>
							Add Strain
						</Button>						
					</Colxx>:""}					
				</Row>
				{selectFacilities.length > 0 ? <Row className="mt-2 pt-2">
					<Colxx lg="12">
						<Label >Facilities</Label>				
						<AvGroup className="w-100 mb-0">
							<AvCheckboxGroup
								inline
								name="facility_id[]"
								onChange={selectFacility => { this.setState({ selectFacility }) }}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the facilities "
								  }
								}}
								defaultValue={defaultFaclity}
							>
								{selectFacilities.map((row)=>{
									return <AvCheckbox customInput label={row.facility_name} value={row.facility_id} key={row.facility_id} />
								})}
							</AvCheckboxGroup >	
						</AvGroup>					
					</Colxx>					
				</Row>:""}
				<Row className="mt-2">
					<Colxx lg="12">
						<Label>Room</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="room_id"
							id="room_id"
							value={this.state.selectRoomType}						
							options={selectRooms}
							onChange={selectRoomType => { this.setState({ selectRoomType }) }}
						/>
					</Colxx>									
				</Row>
				{this.state.selectCategoryType!=null ? <Row className="mt-3">
					{this.state.selectCategoryType.aromas=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Aromas</Label>
							<AvField
								id="aromas"
								name="aromas"
								type="text"
							/>
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.flavors=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Flavors</Label>
							<AvField
								id="flavors"
								name="flavors"
								type="text"
							/>
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.effects=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Effects</Label>
							<AvField
								id="effects"
								name="effects"
								type="text"
							/>
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.symptoms=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Symptoms</Label>
							<AvField
								id="symptoms"
								name="symptoms"
								type="text"
							/>
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.thc=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>THC</Label>
							<AvField
								id="thc"
								name="thc"
								type="number"
								min="0"
							/>
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.cbd=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>CBD</Label>
						  <AvField
							id="cbd"
							name="cbd"
							type="number"
							min="0"
						  />
						</AvGroup>
					</Colxx>:""}
					
					{this.state.selectCategoryType.cannabinoids=='Y' ? <Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Cannabinoids</Label>
						  <AvField
							id="cannabinoids"
							name="cannabinoids"
							type="number"
							min="0"
						  />
						</AvGroup>
					</Colxx>:""}
				</Row>:""}
				<Row className="mt-3">
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Quantity Unit<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="qty_type"
								id="qty_type"
								value={this.state.selectQuantityType}						
								options={selectQuantity}
								onChange={selectQuantityType => { this.setState({ selectQuantityType }) }}
								required={true}
						   />
						</AvGroup>
					</Colxx>	
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Tax Category<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="tax_category"
								id="tax_category"
								placeholder="--Select Tax Category--"
								value={this.state.selectTaxType}						
								options={selectTaxCategory}
								onChange={selectTaxType => { this.setState({ selectTaxType }) }}
								required={true}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row className="mt-3">
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Vendor</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="vendor_id"
								id="vendor_id"
								placeholder="--Select Vendor--"
								value={this.state.selectVendorType}						
								options={selectVendor}
								onChange={selectVendorType => { this.setState({ selectVendorType }) }}
							/>
						</AvGroup>
					</Colxx>	
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Additives</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="additive_template_id"
								id="additive_template_id"
								placeholder="--Select Additives--"
								value={this.state.selectAdditivesType}						
								options={selectAdditives}
								onChange={selectAdditivesType => { this.setState({ selectAdditivesType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row className="mt-3">
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Price Group</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="grade"
								id="grade"
								placeholder="--Select Price Group --"
								value={this.state.selectPriceGroupType}						
								options={selectPriceGroups}
								onChange={selectPriceGroupType => { this.setState({ selectPriceGroupType }) }}
							/>
						</AvGroup>
					</Colxx>				
				</Row>
				<Row>
					<Colxx lg="12" className="mb-2">						
						<AvGroup className="error-l-125 error-t-negative">
							<Label>Documents</Label>
							<InputGroup className="mb-3">
							  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
							  <CustomInput
								type="file"
								name="files[]"
								id="test_document"
								accept="image/*"
								multiple 
								onChange={this.onMultiFileChange}
							  />
							</InputGroup>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.inclusive_taxes}
								onChange={status => { this.setState({ inclusive_taxes:!this.state.inclusive_taxes }) }}
							/>
							<span className="h6 ml-4">Inclusive Taxes</span>
						</Label>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="6" className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.shop_status}
								onChange={status => { this.setState({ shop_status:!this.state.shop_status }) }}
							/>
							<span className="h6 ml-4">Add to Shop</span>
						</Label>
					</Colxx>
					<Colxx lg="6"  className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.is_featured_product}
								onChange={status => { this.setState({ is_featured_product:!this.state.is_featured_product }) }}								
							/>
							<span className="h6 ml-4">Featured Product?</span>
						</Label>					
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="6" className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.is_new}
								onChange={status => { this.setState({ is_new:!this.state.is_new }) }}
							/>
							<span className="h6 ml-4">Is New Arrival?</span>
						</Label>
					</Colxx>
					<Colxx lg="6"  className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.is_top_deal}
								onChange={status => { this.setState({ is_top_deal:!this.state.is_top_deal }) }}								
							/>
							<span className="h6 ml-4">Is Top Deal Product?</span>
						</Label>					
					</Colxx>
				</Row>
				
			</ModalBody>
			<ModalFooter>
				<Button color="primary" id="add-product">
					<IntlMessages id="customer.add" />
				</Button>
				<Button color="secondary" type="button" outline onClick={this.props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
		   
		   <AddStrainModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.getStrainsList}
			/>
	  </Modal>
	  );
  }
};

